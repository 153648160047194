@import "../../../../styles/mixins/media";

.performer-profile {
  border-radius: 0 10px 10px 10px;
  width: 100%;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--sp-2);
  }

  &__status-line {
    display: flex;
    position: absolute;
    top: -110px;
    left: 0;
    right: 0;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__location {
    margin-bottom: var(--sp-6);
    display: flex;
    align-items: center;
    word-break: break-word;
    &.__custom {
      margin-bottom: var(--sp-2);
    }

    &-icon {
      fill: var(--palette-foreground);
      min-width: 24px;
    }

    &-text {
      margin-left: var(--sp-2);
      color: var(--palette-neutral);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__avatar {
    min-width: 150px;
    width: 175px;
    height: 210px;
  }

  &__avatar-container {
    margin-bottom: var(--sp-6);
  }

  &__about {
    width: 100%;
    &-header {
      margin-bottom: var(--sp-2);
    }
  }

  &__practice-area {
    margin-top: var(--sp-6);

    &-tags {
      margin-top: var(--sp-3);
    }
  }

  &__questionnaire {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-8);
    margin-top: var(--sp-8);

    &-text {
      word-break: break-word;
      white-space: pre-wrap;
    }
  }

  @include portrait-tablet {
    padding: var(--sp-8);
    border-radius: 10px;

    &__top {
      margin-bottom: var(--sp-3);
      .performer-profile__status-line {
        display: block;
      }
    }

    &__location {
      margin-bottom: var(--sp-6);
      &.__custom {
        margin-bottom: var(--sp-4);
      }
    }

    &__container {
      flex-direction: row;
      align-items: unset;
    }

    &__avatar:not(.__custom) {
      width: 150px;
      height: 181px;
    }

    &__avatar-container {
      margin: 0;
    }

    &__status-line {
      position: static;
      top: auto;
      left: auto;
      margin-right: var(--sp-n4);
    }

    & &__favorite {
      padding-left: var(--sp-4);
      padding-right: var(--sp-4);

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 1;
      }

      .favorite__text {
        display: block;
      }
    }

    &__about {
      margin-left: var(--sp-8);
      &-header {
        margin-bottom: var(--sp-4);
      }
    }

    &__questionnaire {
      grid-template-columns: 1fr 1fr;
    }
  }
}
