@import "../../styles/mixins/commonJoinForm";

.go-live-modal {
  max-width: 440px;
  margin: 0 auto;

  &__buttons {
    display: flex;
    margin-top: var(--sp-5);
    justify-content: space-between;
    > *:not(:first-child) {
      margin-left: var(--sp-2);
    }
  }
  &__btn {
    width: 50%;
  }
}
