@import "../../../../styles/mixins/media";

.navigation-menu {
  display: none;
  position: relative;

  &__list {
    padding: var(--sp-5);
    margin: 0 0 var(--sp-6);
  }

  &__item {
    width: 100%;
    border-radius: 10px;
    position: relative;
    padding: 0 var(--sp-4);
    padding-left: var(--sp-10);
    transition: background 0.25s ease-in-out;
    height: 40px;

    margin-bottom: var(--sp-2);

    &:last-child {
      margin-bottom: 0;
    }

    &-icon {
      position: absolute;
      fill: var(--palette-foreground);
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.active {
      font-weight: 600;
    }

    &.active,
    &:hover,
    &:active,
    &:focus {
      background: var(--palette-main-bg);
      color: var(--palette-foreground);
    }
  }

  @include desktop-up {
    display: block;
  }
}
