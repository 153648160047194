@import "../../styles/mixins/media";

.footer {
  background: var(--palette-foreground);
  color: var(--palette-neutral);
  padding: var(--sp-12) var(--sp-6);

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__links {
    display: flex;

    a {
      transition: opacity 0.1s ease-in-out;
      color: var(--palette-neutral);
    }
  }

  &__links {
    flex-direction: column;
    text-align: center;
    padding: 0;
    margin: 0 0 var(--sp-12);

    &-item {
      padding-bottom: var(--sp-6);

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__link {
    color: var(--palette-neutral);
  }

  @include desktop-up {
    padding: var(--sp-11) 0;

    &__inner {
      flex-direction: unset;
    }

    &__links,
    &__copyright {
      flex-direction: unset;
      margin: 0;
    }

    &__copyright {
      order: -1;
    }

    &__links {
      &-item {
        margin: 0 var(--sp-4);
        padding: 0;
      }
    }
  }
}
