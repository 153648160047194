@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/typography";

.performer-card {
  margin-bottom: var(--sp-3);
  transition: box-shadow 0.15s ease-in-out;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }

  &__status-line {
    position: absolute;
    top: var(--sp-n2);
    right: var(--sp-n2);
    width: 40px;
    height: 40px;
    @include body;
    line-height: 1;

    & .base-button {
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    display: flex;
    width: 100%;
  }

  &__description {
    width: 100%;
  }

  &__avatar-container {
    margin: 0 auto var(--sp-4);
  }

  &__avatar {
    width: 130px;
    height: 155px;
  }

  &__link {
    display: block;
    width: 100%;
    margin-bottom: var(--sp-2);
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    @include body-big;
  }

  &__about {
    @include body;
    margin-bottom: var(--sp-4);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  &__location {
    display: flex;
    align-items: center;
    margin-bottom: var(--sp-2);

    &-icon {
      fill: var(--palette-foreground);
      min-width: 24px;
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include label;
      margin-left: var(--sp-2);
      color: var(--palette-neutral);
    }
  }

  &__bottom-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--sp-4);
    position: sticky;
    top: 100%;
  }

  &__button {
    width: calc(50% - var(--sp-3) / 2);
    &:not(&:last-child) {
      margin-right: var(--sp-3);
    }
  }

  @include desktop-up {
    &__bottom-buttons {
      justify-content: flex-end;
    }
    &__button {
      width: fit-content;
    }

    &:hover {
      z-index: 1;
      box-shadow: var(--sp-2) var(--sp-2) var(--sp-6) 0 rgba(var(--palette-black-rgb), 0.1);
    }
  }

  @include portrait-tablet {
    margin-bottom: var(--sp-6);

    &__inner {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    &__avatar-container {
      margin: 0;
      margin-right: var(--sp-8);
    }

    &__tags {
      margin-bottom: var(--sp-3);
    }

    &__container {
      flex-grow: 1;
      width: 0;
    }

    &__avatar {
      width: 150px;
      height: 181px;
    }

    &__name {
      @include headline-2;
    }

    &__link {
      padding-right: var(--sp-8);
      margin-bottom: var(--sp-4);
    }

    &__about {
      @include body;
    }

    &__location {
      &-text {
        @include body;
      }
    }
  }
}
