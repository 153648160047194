@import "../../styles/mixins/media";

.refill-account {
  &__loading {
    margin: 0 auto;
  }

  &__header-container {
    position: relative;

    margin-bottom: var(--sp-2);
  }

  &__header {
    &.__center {
      margin: 0 auto;
      min-height: var(--sp-8);
      padding: 0 var(--sp-9);
    }
    word-break: break-word;
  }

  &__back-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__buttons {
    position: sticky;
    bottom: 0;
    background: var(--palette-white);
    // TODO: need another solution
    box-shadow: 0 30px 25px 40px var(--palette-white);

    display: flex;
    justify-content: space-between;

    & > * {
      width: calc(50% - var(--sp-2));
    }
    &.__unfixed-buttons {
      top: 100%;
    }
  }

  @include portrait-tablet {
    &__header-container {
      max-width: 360px;
      margin-bottom: var(--sp-6);
    }

    &__header {
      text-align: left;
    }

    &__buttons {
      justify-content: flex-end;

      & > * {
        min-width: 115px;
        width: auto;
        &:not(:last-child) {
          margin-right: var(--sp-4);
        }
      }

      &.__wide-buttons {
        & > * {
          width: calc(50% - var(--sp-2));
        }
      }
    }
  }
}
