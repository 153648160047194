.online-status {
  padding: 0 6px;
  height: 17px;

  font-size: 9px;
  font-weight: 600;
  border-radius: 10px;

  text-transform: uppercase;
  color: var(--palette-white);
  background-color: var(--palette-neutral-lighten-1);

  &.__live-now {
    background-color: var(--palette-positive);
  }

  &.__online {
    background-color: var(--palette-primary);
  }
}