@import "../../../../styles/mixins/media";

.navigation-tabs {
  &__horizontal-menu {
    display: none;
    justify-content: center;
    margin-bottom: var(--sp-8);

    &-link {
      &:not(:last-child) {
        margin-right: var(--sp-8);
      }

      &.active {
        color: var(--palette-foreground);
        box-shadow: 0 1px 0 var(--palette-primary);
      }
    }
    &-mobile {
      margin-bottom: var(--sp-6);
    }
  }

  @include desktop-up {
    &__horizontal-menu{
      display: flex;
    }
  }
}
