.steps-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--palette-foreground);
  font-size: 19px;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: var(--palette-input-bg);
    cursor: default;
    &.__active {
      background: var(--palette-white);
      box-shadow: inset 0 0 0 2px var(--palette-primary);
      cursor: pointer;
    }
    &.__passed {
      color: var(--palette-white);
      background: var(--palette-primary);
      cursor: pointer;
      & + .steps-mobile__separator {
        background: var(--palette-primary);
      }
    }
  }
  &__separator {
    height: 2px;
    flex-grow: 1;
    pointer-events: none;
    background: var(--palette-input-bg);
  }
}
