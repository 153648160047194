@import "../../styles/mixins/media";

.page-user-area {
  &__header {
    margin-bottom: var(--sp-6);
  }

  @include desktop-up {
    &__header {
      text-align: left;
    }
  }

  @include desktop-up {
    &__header {
      margin-bottom: var(--sp-10);
    }
  }
}
