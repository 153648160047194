@import "../../styles/mixins/media";

.page-messages {
  display: flex;
  flex-direction: column;
  padding-bottom: calc(env(safe-area-inset-bottom) + var(--sp-4));

  & > * {
    height: 0;
    flex-grow: 1;
    & > * {
      height: 100%;
    }
  }
  &__header {
    display: flex;
    margin-bottom: var(--sp-5);
    align-items: center;
    height: 35px;
    flex-grow: unset;
    &.__center-text {
      justify-content: center;
    }
  }

  &__heading {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    &-link {
      overflow: hidden;
    }
  }

  &__container {
    display: flex;
    height: 100%;
  }

  &__content-threads {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: var(--sp-4);
  }

  &__back-button {
    flex-shrink: 0;
    padding: 0;
    width: 28px;
    height: 28px;
  }

  &__empty-right-block {
    display: none;
  }

  @include portrait-tablet {
    &__header {
      margin-bottom: var(--sp-6);
    }
  }

  @include desktop-up {
    height: calc(100vh - 80px);

    &__header {
      margin-bottom: var(--sp-10);
    }

    &__heading {
      text-align: left;
    }

    &__content-threads {
      border-radius: 10px 0 0 10px;
      border-right: 1px solid rgba(var(--palette-neutral-lighten-2-rgb), 0.45);
      width: 280px;
      min-width: 280px;
    }

    &__back-button {
      display: none;
    }

    &__empty-right-block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      border-radius: 0 10px 10px 0;
      color: var(--palette-neutral);
      background-color: var(--palette-white);
    }
  }
}
