@import "../../styles/mixins/media";
@import "../../styles/mixins/typography";

.social-login {
  &__text {
    margin-bottom: 20px;
    @include label;
    text-align: center;
    color: var(--palette-neutral);
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    padding: 0;
    margin-right: 20px;
    &:last-child {
      margin: 0;
    }

    &-icon {
      transition: fill 0.25s linear;

      &.__google {
        fill: var(--palette-negative);
        &:hover {
          fill: var(--palette-negative-darken-1);
        }
      }

      &.__twitter {
        fill: var(--palette-twitter-color);
        &:hover {
          fill: var(--palette-twitter-color-hover);
        }
      }

      &.__facebook {
        fill: var(--palette-facebook-color);

        &:hover {
          fill: var(--palette-facebook-color-hover);
        }
      }
    }
  }

  &__spinner {
    margin: 0 auto;
  }
  @include portrait-tablet {
    &__text {
      @include body;
    }
  }
}
