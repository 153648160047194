.base-tabs {
  display: flex;
  justify-content: space-between;

  &__item {
    color: var(--palette-neutral);
    cursor: pointer;
    border-bottom: 1px solid transparent;
    &:hover {
      color: var(--palette-foreground);
    }
    &.__active {
      color: var(--palette-foreground);
      border-bottom-color: var(--palette-primary);
    }
    &.__passed {
      color: var(--palette-primary-darken-1);
    }
    &.__inactive {
      cursor: default;
      pointer-events: none;
      &,
      &:hover {
        color: var(--palette-neutral);
      }
    }
  }
}
