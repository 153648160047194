@import "../../../../styles/mixins/media";

.performer-list {
  &__load-more {
    margin: 0 auto;
  }

  @include portrait-tablet {
    &__load-more {
      margin-top: 60px;
    }
  }
}