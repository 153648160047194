@import "../../styles/mixins/media";
@import "../../styles/mixins/divider";

.header {
  &__container {
    background: var(--palette-white);
    position: relative;
    z-index: 3;
    &.__row {
      flex-direction: row-reverse;
    }
  }

  &__logo {
    height: 50px;
    flex-grow: 1;
  }

  &__logo-img {
    max-width: 100%;
    max-height: 100%;
  }

  &__desktop-nav {
    display: none;
  }

  &__go-live-button {
    margin-left: auto;
  }
  &__go-live-icon {
    fill: var(--palette-primary);
  }

  &__nav {
    display: none;
  }

  &__inner {
    background-color: var(--palette-white);
    position: relative;
    z-index: 3;
    flex-grow: 1;
    height: var(--mobile-header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--sp-4);
  }

  &__buttons {
    display: flex;

    &-button {
      margin-left: var(--sp-2);
      width: 40px;
      padding: 0;
    }

    .text {
      display: none;
    }
  }

  &__logged-user {
    &-menu {
      display: flex;
      align-items: center;

      .icon {
        display: none;
      }
    }
  }

  &__base-menu-prepend-item {
    display: flex;
    align-items: center;
    justify-content: center;
    &.__mobile {
      height: auto;
      padding-left: 0;
      border-radius: 0;
      padding-right: 0;
      justify-content: flex-start;
      background-color: initial;
      transition: initial;
      &,
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background-color: transparent;
        fill: var(--palette-primary);
        color: var(--palette-primary);
      }
    }
  }

  @include portrait-tablet {
    &__buttons {
      &-button {
        width: auto;
        padding-left: var(--sp-5);
        padding-right: var(--sp-5);
      }

      .icon {
        margin-right: var(--sp-1);
      }

      .text {
        display: block;
      }
    }
    &__logged-user {
      &-menu {
        .icon {
          display: block;
        }
      }

      &-avatar {
        margin-right: var(--sp-2);
      }
    }
    &__base-menu {
      .base-menu__activator-container:after {
        transform: translateX(calc(-50% - var(--sp-2))) rotate(45deg);
      }
    }
  }

  @include desktop-up {
    &__logo {
      flex-grow: 0;
    }
    &__mobile-nav {
      display: none;
    }
    &__desktop-nav {
      display: block;
    }
    &__inner {
      height: 80px;
    }
  }
}
