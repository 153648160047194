@import "../../styles/mixins/media";

.page-balance-history {
  padding: 0;

  &__heading {
    display: none;
  }

  @include desktop-up {
    padding: var(--sp-8);

    &__heading {
      display: block;
    }
  }
}
