@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/divider";

.performer-reviews-card {
  padding: 0;
  border-radius: 10px 0 10px 10px;

  &__header {
    &.__border-bottom {
      @include divider;
    }
  }

  &__total-records {
    color: var(--palette-neutral);
  }

  &__heading,
  &__total-records {
    display: none;
  }

  &__review-btn {
    margin: var(--sp-5) auto;
  }

  &__empty-content {
    padding: var(--sp-16) 0;
    color: var(--palette-neutral);
  }

  @include desktop-up {
    padding: var(--sp-8);
    border-radius: 10px;

    &__header {
      margin-bottom: var(--sp-8);
      justify-content: space-between;
      align-items: center;
      &.__border-bottom {
        @include divider-none;
      }
    }

    &__heading,
    &__total-records {
      display: block;
    }

    &__review-btn {
      margin: 0;
    }

    &__empty-content {
      border: 1px solid var(--palette-neutral-lighten-2);
      padding: var(--sp-9) 0;
      margin: 0;
      border-radius: 10px;
    }
  }
}
