.message-attach-btn {
  &__menu .base-menu {
    &__activator-container:after {
      bottom: 100%;
      margin: 0;
    }
    &__list {
      right: auto;
      top: auto;
      left: 0;
      bottom: 100%;
      margin: 0 0 var(--sp-1) var(--sp-n4);
    }
  }
}
