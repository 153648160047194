.base-counter {
  font-size: 10px;
  font-weight: 700;
  height: 16px;
  min-width: 16px;
  padding: 0 var(--sp-1);
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  color: var(--palette-white);
  background-color: var(--palette-primary);
}
