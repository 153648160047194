@import "../../../../styles/mixins/media";

.right-panel {
  width: 100%;

  &__section {
    display: flex;
    width: 100%;
    &:not(.__text-chat) {
      height: 100%;
    }
    &.__hide {
      display: none;
    }
  }

  @include desktop-up {
    &__section.__text-chat,
    &__section {
      height: 100%;
      border-radius: var(--sp-2);
    }
  }
}
