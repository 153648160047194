@import "../../../../styles/mixins/media";

.panel-control-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--sp-3);

  @include desktop-up {
    flex-direction: row;
    width: 100%;
    &__btn {
      &.__messages-btn {
        display: none;
      }
    }
  }
}
