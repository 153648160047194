@import "../../styles/mixins/typography";

.text-clamp {
  @include body;
  display: block;
  word-break: break-word;
  position: relative;

  &__button {
    background: none;
    border: none;
    outline: none;
    margin-top: var(--sp-4);
    cursor: pointer;
    color: var(--palette-foreground);
    font-weight: 600;
    padding: 0 var(--sp-3) 0 0;
    height: 24px;

    &:focus {
      box-shadow: none;
    }

    &.__blue-hover {
      &:hover {
        color: var(--palette-primary);
      }
    }

    &.__shadow {
      &:after {
        content: "";
        pointer-events: none;
        position: absolute;
        left: 0;
        bottom: var(--sp-8);
        width: 100%;
        height: 30px;
        background: linear-gradient(
          180deg,
          rgba(var(--palette-white-rgb), 0) 0%,
          rgba(var(--palette-white-rgb), 1) 100%
        );
      }
    }
  }
}
