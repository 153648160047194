.upload-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__wrapper {
    position: relative;
    width: 100%;
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__closer {
    position: absolute;
    top: -13px;
    right: -13px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background: var(--palette-negative);
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 1px;
      border-radius: 1px;
      background: var(--palette-white);
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &__button {
    width: 100%;
    white-space: nowrap;
    padding: 0;
  }
  &__icon {
    fill: var(--palette-foreground);
  }
  &__duration {
    position: absolute;
    right: var(--sp-1);
    bottom: var(--sp-1);
    border-radius: 5px;
    backdrop-filter: blur(4px);
    color: var(--palette-white);
    background: var(--palette-foreground);
  }
}
