@import "../../../../styles/mixins/media";

.end-appointment-screen {
  display: flex;
  position: relative;
  z-index: 10;
  justify-content: center;
  background: linear-gradient(305.27deg, var(--palette-foreground) -9.6%, var(--palette-foreground-lighten-1) 110.4%);
  width: 100%;
  height: 100%;

  &__container {
    max-width: 190px;
    width: 100%;
    margin-top: 27%;
  }

  &__user {
    display: flex;
    align-items: center;

    &-name {
      line-height: 17px;
      color: var(--palette-white);
      overflow: hidden;
      display: -webkit-box;
      word-break: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__favourite-btn,
  &__review-btn {
    &.__transparent {
      width: auto;
      background: transparent;
      color: var(--palette-white);
      fill: var(--palette-white);
      padding: 0;
      &:hover {
        background: transparent;
      }
      &:active,
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }

  &__text {
    color: var(--palette-neutral-lighten-2);
  }

  @include mobile-landscape {
    align-items: center;

    &__container {
      margin-top: 0;
    }
  }

  @include desktop-up {
    align-items: center;

    &__container {
      margin-top: 0;
    }
  }
}
