@import "../../styles/mixins/media";

.messages-list-view {
  overflow: auto;
  padding: var(--sp-4);

  &__clamp-block {
    flex-grow: 1;
  }

  &__container {
    display: flex;
    flex-direction: column-reverse;
  }

  &__new-message {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: var(--sp-4);
    &-content {
      color: var(--palette-primary);
      background-color: rgba(var(--palette-primary-lighten-2-rgb), 0.2);
      padding: 7px var(--sp-2);
      border-radius: 10px;
    }
    &:before {
      content: "";
      position: absolute;
      height: 1px;
      left: 0;
      top: 50%;
      width: 100%;
      background-color: rgba(var(--palette-primary-lighten-2-rgb), 0.2);
    }
  }

  @include desktop-up {
    padding: var(--sp-6) var(--sp-8);
  }
}
