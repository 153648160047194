.media-settings {
  flex-direction: column;
  position: relative;
  background-color: var(--palette-white);

  &__close {
    position: absolute;
    right: var(--sp-2);
    top: var(--sp-2);
  }
}
