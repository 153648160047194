.checkbox-input {
  display: flex;
  justify-content: center;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .__checkmark {
      background-color: var(--palette-primary);
      border: 1px solid var(--palette-primary);
      &:after {
        display: block;
      }
    }

    &:focus ~ .__checkmark {
      border: 1px solid rgba(var(--palette-primary-rgb), 0.8);
    }
  }

  &__container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    height: 20px;

    &:hover input ~ &.__checkmark {
      border: 1px solid var(--palette-primary);
    }

    &.__checkmark {
      position: relative;
      margin-right: var(--sp-3);
      height: 20px;
      width: 20px;
      border-radius: 5px;
      border: 1px solid var(--palette-neutral);

      &::after {
        content: "";
        position: absolute;
        display: none;

        left: 50%;
        top: 45%;
        width: 5px;
        height: 8px;
        border: solid var(--palette-white);
        border-width: 0 1px 1px 0;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
