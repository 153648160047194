.base-menu {
  position: relative;
  z-index: 10;

  &.__active {
    .base-menu__activator-container:after {
      display: block;
    }
  }

  &__activator-container:after {
    content: "";
    display: none;
    position: absolute;
    z-index: 10;
    width: 24px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    margin-top: 10px;
    border-radius: 3px;
    background: var(--palette-white);
  }

  &__activator {
    color: var(--palette-foreground);
    position: relative;
    cursor: pointer;
  }

  &__list {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    position: absolute;
    right: 0;
    top: 100%;

    padding: var(--sp-6);
    border-radius: 10px;
    background: var(--palette-white);
    box-shadow: 35px 19px 80px rgba(var(--palette-black-rgb), 0.2);
    margin: var(--sp-3) var(--sp-n2) 0 0;
  }

  &__item {
    display: flex;
    align-items: center;
    line-height: 1;
    color: var(--palette-foreground);
    fill: var(--palette-foreground);
    cursor: pointer;
    margin-bottom: var(--sp-3);
    white-space: nowrap;
    &-icon-container {
      display: flex;
      margin-right: var(--sp-2);
      width: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: var(--palette-primary);
      fill: var(--palette-primary);
    }

    &.__title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: auto;
      color: var(--palette-neutral);
    }
  }

  &.__spaced {
    .base-menu__list {
      padding: var(--sp-4) var(--sp-2) var(--sp-5);
      & > * {
        margin: var(--sp-2) var(--sp-4) var(--sp-1);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
