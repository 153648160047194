@mixin portrait-mobile {
  @media screen and (min-width: 428px) {
    @content;
  }
}

@mixin portrait-tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: 1000px) {
    @content;
  }
}

@mixin mobile-landscape {
  @media screen and (orientation: landscape) and (max-device-width: 1000px) {
    @content;
  }
}
