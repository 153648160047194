@import "../../styles/mixins/commonJoinForm";
@import "../../styles/mixins/media";
.client-signup {
  @include joinForm;
  &__checkbox {
    padding: var(--sp-4) 0 var(--sp-2);
  }
  @include portrait-tablet {
    &__checkbox {
      padding: var(--sp-2) 0 0;
    }
  }
}
