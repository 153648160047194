@import "../../styles/mixins/media";

.modal-window {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  position: fixed;
  z-index: 100;
  padding: var(--sp-8) var(--sp-7);
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    min-width: 305px;
    padding: var(--sp-6);
    border-radius: 10px;
    position: relative;
    background: var(--palette-white);
    max-height: 100%;
    overflow: auto;
  }

  &__close {
    position: absolute;
    top: 7px;
    right: 7px;
    fill: var(--palette-foreground);
  }

  &__backdrop {
    background-color: rgba(var(--palette-foreground-rgb), 0.56);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.__mobile-full-screen {
    padding: 0;
    .modal-window__content {
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }

  @include portrait-tablet {
    &__content {
      min-width: 400px;
      padding: var(--sp-8);
    }

    &.__mobile-full-screen {
      padding: var(--sp-8) var(--sp-7);
      .modal-window__content {
        border-radius: 10px;
        width: unset;
        height: unset;
      }
    }
  }
}
