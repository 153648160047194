@import "../../styles/mixins/media";
@import "../../styles/mixins/typography";

.tag-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &.__disabled {
    .tag-input {
      &__label {
        color: var(--palette-neutral);
      }
      &__input-wrapper {
        cursor: default;
        pointer-events: none;
      }
    }
    .react-tags__selected-tag-name {
      color: var(--palette-neutral);
    }
  }

  &__top-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: var(--sp-2);
    width: 100%;
  }

  &__input-wrapper {
    width: 100%;
    position: relative;
  }

  &__label {
    color: var(--palette-neutral);
  }
}

.react-tags {
  position: relative;
  padding: 12px 7px 7px 12px;
  min-height: 50px;
  border-radius: var(--sp-2);
  cursor: text;
  background: var(--palette-input-bg);
  &.is-focused {
    box-shadow: inset 0 0 0 1px rgba(var(--palette-primary-rgb), 0.8);
  }

  &__selected {
    display: inline;
  }

  &__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 var(--sp-1) var(--sp-1) 0;
    height: 26px;
    white-space: nowrap;
    padding: 0 var(--sp-2);
    @include small;
    line-height: 1;
    border: none;
    border-radius: 5px;
    background: var(--palette-white);
    cursor: pointer;
  }

  &__selected-tag:hover,
  &__selected-tag:focus {
    box-shadow: inset 0 0 0 1px var(--palette-foreground);
  }

  &__search {
    display: inline-block;
    max-width: 100%;
  }

  &__search-input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    @include body;
    background: none;
    height: 25px;
    &::placeholder {
      color: var(--palette-neutral);
    }
  }

  &__search-input::-ms-clear {
    display: none;
  }

  &__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
    box-shadow: 35px 19px 80px rgba(var(--palette-black-rgb), 0.2);
    border-radius: var(--sp-2);
    background: var(--palette-white);
    overflow: hidden;
  }

  &__suggestions ul {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1;
  }

  &__suggestions li {
    color: var(--palette-neutral);
    padding: calc(var(--sp-3) / 2) var(--sp-6);
    cursor: pointer;
    &:first-child {
      padding-top: var(--sp-6);
    }
    &:last-child {
      padding-bottom: var(--sp-6);
    }
  }

  &__suggestions li mark {
    color: var(--palette-foreground);
    background: none;
  }

  &__suggestions li:not(.is-disabled):hover {
    &,
    & mark {
      color: var(--palette-primary);
    }
  }

  &__suggestions li.is-active {
    &,
    & mark {
      color: var(--palette-primary);
    }
  }

  &__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: default;
  }

  @include portrait-tablet {
    &__search {
      position: relative;
    }
    &__suggestions {
      width: 240px;
    }
  }
}
