@import "../../../../styles/mixins/media";

.appointments-page {
  &__wrapper {
    max-width: 480px;
    margin: 0 auto;
  }
  @include desktop-up {
    &__wrapper {
      min-height: 400px;
    }
  }
}
