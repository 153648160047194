@import "../../../../styles/mixins/media";

.navigation {
  display: flex;
  align-items: center;
  &__content {
    border-top: 1px solid transparent;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 2;
    width: 100%;
    background: white;
    padding: var(--sp-12) var(--sp-8);
    transform: translateY(-100%);
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-top-color 0s 0s ease-in-out;

    &.__active {
      transition: transform 0.25s ease-in-out, box-shadow 0.15s ease-in-out, border-top-color 0.15s 0.25s ease-in-out;
      box-shadow: 0 30px 35px rgba(var(--palette-neutral-lighten-2-rgb), 0.9);
      transform: translateY(0);
      border-top-color: rgba(var(--palette-neutral-lighten-2-rgb), 0.9);
    }
  }

  &__activator {
    position: relative;
    background-color: var(--palette-white);
    z-index: 3;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-icon {
      &.__active-icon {
        display: none;
      }
    }
    &-link {
      margin-bottom: var(--sp-8);
      &:last-child {
        margin: 0;
      }
      &.active {
        .navigation__nav-icon {
          display: none;
          &.__active-icon {
            display: block;
          }
        }
      }
    }
  }

  @include desktop-up {
    margin: 0 auto;
    height: 100%;
    &__content {
      height: 100%;
      position: static;
      border-top: none;
      padding: 0;
      transform: translateY(0);
      background: none;
    }

    &__nav {
      flex-direction: row;
      height: 100%;
      &-link {
        height: 100%;
        margin-bottom: 0;
        margin-right: var(--sp-8);
        &.active {
          box-shadow: 0 1px 0 0 var(--palette-primary);
        }
      }
    }

    &__burger-button {
      display: none;
    }
  }
}
