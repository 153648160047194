@import "src/styles/mixins/media";
.performer-verification {
  display: flex;
  flex-direction: column;
  width: 480px;
  max-width: 100%;
  &__icon {
    border-radius: 50%;
    background: var(--palette-primary);
    fill: var(--palette-white);
    padding: var(--sp-3);
    margin: 0 auto var(--sp-4);
  }
  &__short-input {
    @include portrait-tablet {
      width: calc(50% - var(--sp-2));
      &:nth-child(odd) {
        margin-right: var(--sp-4);
      }
    }
  }
  &__desktop-only {
    display: none;
    @include portrait-tablet {
      display: initial;
    }
  }
  &__mobile-only {
    display: initial;
    @include portrait-tablet {
      display: none;
    }
  }
}
