@import "../../../../../../styles/mixins/media";

.appointments-range {
  &__paginator {
    justify-content: space-between;
    margin: 0 var(--sp-n2);
  }

  @include desktop-up {
    &__content {
      min-height: 535px;
    }
    &__list {
      min-height: inherit;
    }
    &__paginator {
      max-width: 250px;
      margin-left: auto;
    }
  }
}
