@import "../../../../styles/mixins/media";

.volume-control {
  position: relative;
  display: block;

  @include desktop-up {
    &.__dragged,
    &:hover {
      .volume-control {
        &__button > * {
          background-color: transparent;
        }

        &__slider {
          padding-top: var(--sp-3);
          padding-bottom: var(--sp-9);
          height: 137px;
          opacity: 1;
          box-shadow: 0 3px 20px rgba(var(--palette-black-rgb), 0.2);
          transition-delay: 0s;

          & > div {
            opacity: 1;
          }
        }
      }
    }
  }

  &__slider {
    display: none;

    @include desktop-up {
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      padding-top: 0;
      padding-bottom: 0;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      opacity: 0;
      background-color: var(--palette-white);
      border-radius: 40px;
      transition: height 0.25s ease-in-out, box-shadow 0.25s linear, opacity 0s 2s, padding 0.25s;

      & > div {
        opacity: 0;
        transition: opacity 0.1s linear;
      }
    }
  }
}

