@import "../../../../styles/mixins/media";

.transaction-list {
  &__heading {
    display: none;

    padding: 0 var(--sp-6);
    margin-bottom: var(--sp-6);
    font-size: 12px;
    color: var(--palette-neutral);

    &-type {
      flex-basis: 54%;
    }
    &-duration {
      flex-basis: 13%;
    }
    &-amount {
      margin-left: auto;
    }
  }

  @include desktop-up {
    &__heading {
      display: flex;
    }
  }
}
