@import "../../styles/mixins/media";

.profile-settings {
  &__button {
    width: 100%;
    margin-top: var(--sp-8);
    margin-left: auto;
  }

  &__container-avatar {
    display: flex;
    justify-content: center;
    margin-bottom: var(--sp-6);
  }

  &__avatar {
    width: 175px;
    height: 210px;
  }

  &__success {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--sp-3);
    height: 50px;
    border-radius: 10px;
    color: var(--palette-positive-darken-2);
    background: var(--palette-positive-lighten-2);

    &-icon {
      margin-right: var(--sp-2);
    }
  }

  @include portrait-tablet {
    &__button {
      width: unset;
    }
    &__form,
    &__success {
      max-width: 480px;
      margin: 0 auto;
    }
    &__success {
      margin-bottom: var(--sp-6);
    }
    &__container-avatar {
      margin-bottom: var(--sp-8);
    }
    &__avatar {
      width: 150px;
      height: 181px;
    }
  }
}
