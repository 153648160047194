@import "src/styles/mixins/media";

.base-filter-container {
  --base-filter-container__search-height: 40px;
  --base-filter-container__search-radius: 10px;
  @include desktop-up {
    padding: var(--sp-5) var(--sp-5) var(--sp-8) var(--sp-5);
  }
  &.__hide {
    .base-filter-container {
      &__list-wrap {
        display: none;
        @include desktop-up {
          display: block;
        }
      }
    }
  }
  &__title {
    &-wrap {
      position: relative;
      height: var(--base-filter-container__search-height);
      border-radius: var(--base-filter-container__search-radius);
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    &-text {
      display: flex;
      align-items: center;
      @include desktop-up {
        padding-left: var(--sp-3);
      }
    }
    &-icon {
      width: 8px!important;
      @include desktop-up {
        display: none;
      }
    }
  }
  &__search {
    &-wrap {
      position: absolute;
      right: 0;
      width: var(--base-filter-container__search-height);
      height: 100%;
      border-radius: var(--base-filter-container__search-radius);
      transition: background-color 0.2s ease-in-out, width 0.5s ease-in-out;
      display: flex;
      align-items: center;
      > * {
        height: 100%;
      }
      &.__focus {
        transition: background-color 0.2s ease-in-out 0.5s, width 0.5s ease-in-out;
      }
      &:hover,
      &:focus-within {
        transition: background-color 0.2s ease-in-out, width 0.5s ease-in-out;
        background: var(--palette-input-bg);
      }
      &:focus-within,
      &.__show {
        width: 100%;
        background: var(--palette-input-bg);
      }
      &.__show {
        .base-filter-container {
          &__search-input {
            padding-right: var(--base-filter-container__search-height);
          }
          &__search-clear-icon {
            display: block;
          }
        }
      }
    }
    &-input {
      flex: 1 0 auto;
      width: 0;
      border: 0;
      background: transparent;

      padding-left: var(--base-filter-container__search-height);
      cursor: pointer;
      :focus {
        cursor: unset;
      }
    }
    &-icon,
    &-clear-icon {
      height: var(--base-filter-container__search-height);
      width: var(--base-filter-container__search-height);
      cursor: pointer;
    }
    &-icon {
      padding: 8px;
      pointer-events: none;
      position: absolute;
      left: 0;
    }
    &-clear-icon {
      padding: calc(var(--base-filter-container__search-height) / 2 - 7px);
      fill: var(--palette-neutral);
      display: none;
    }
  }
  &__list {
    &-wrap {
      @include desktop-up {
        padding: 0 var(--sp-3);
      }
    }
    &.__long-list {
      overflow: auto;
      max-height: 320px;
      min-height: 15px;
    }
    > * {
      justify-content: flex-start;
      &:not(:last-child) {
        margin-bottom: var(--sp-3);
      }
    }
  }
}
