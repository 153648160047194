.messages-thread-list {
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: none;
  }

  &.__align-center {
    align-items: center;
    justify-content: center;
  }

  &__container {
    width: 100%;
  }

  &__scrollbar {
    margin-right: -20px;
    padding-right: 20px;
  }
}
