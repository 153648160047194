@import "../../styles/mixins/typography";
@import "../../styles/mixins/media";

.payment-booking {
  max-width: 440px;
  margin: 0 auto;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--sp-14);
    height: var(--sp-14);
    border-radius: 50%;
    fill: var(--palette-white);
    background: var(--palette-primary);
  }

  &__heading {
    @include headline-2;
  }

  &__text {
    margin-bottom: var(--sp-7);
    text-align: center;
    &.__error {
      color: var(--palette-negative);
    }
  }

  &__description {
    text-align: center;
    @include body-big;
  }

  &__content {
    width: 100%;
  }

  &__wrapper-buttons {
    display: flex;
    justify-content: space-between;
    &-btn {
      width: calc(50% - var(--sp-1));
    }
  }

  @include desktop-up {
    &__heading {
      @include headline-1;
    }

    &__description {
      @include headline-2;
    }

    &__text {
      margin-bottom: var(--sp-6);
    }

    &__success-window-description {
      display: block;
      padding: 0 var(--sp-8);
    }
  }
}
