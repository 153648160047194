@import "../../styles/mixins/media";
@import "../../styles/mixins/typography";

.base-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: var(--sp-2);
  }

  &__input-wrapper {
    width: 100%;
    position: relative;
  }

  &__label {
    @include label;
    color: var(--palette-neutral);
  }

  &__input {
    width: 100%;
    height: 50px;
    border-radius: 10px;

    color: var(--palette-foreground);
    text-align: center;
    border: 1px solid transparent;

    background: var(--palette-input-bg);
    padding: 0 var(--sp-5);
    font-size: 13px;
    outline: none;

    &::placeholder {
      color: var(--palette-neutral);
    }

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type="password"]::-ms-reveal,
    &[type="password"]::-ms-clear {
      display: none;
    }

    &:focus {
      border-color: rgba(var(--palette-primary-rgb), 0.8);
    }
  }

  &.__error {
    .base-input__input {
      border: 1px solid var(--palette-negative);
    }
    .base-input__label {
      color: var(--palette-negative);
    }
  }

  &.__success {
    .base-input__input {
      border: 1px solid var(--palette-positive);
      background-color: var(--palette-positive-bg);
    }
  }

  &.__with-left-icon {
    .base-input__input {
      padding-left: var(--sp-10);
    }
  }
  &.__with-right-icon {
    .base-input__input {
      padding-right: var(--sp-10);
    }
  }
  &.__with-icons {
    .base-input__input {
      padding-right: var(--sp-10);
      padding-left: var(--sp-10);
    }
  }

  &.__text-large {
    .base-input__input {
      font-size: 15px;
      font-weight: 600;
    }
  }

  &.__disabled {
    .base-input {
      &,
      &__input,
      &__icon-left,
      &__icon-right {
        color: var(--palette-neutral);
        fill: var(--palette-neutral);
        cursor: default;
      }
    }
  }

  &__hint {
    width: 100%;
    text-transform: lowercase;
    margin-top: var(--sp-3);
    &:first-letter {
      text-transform: uppercase;
    }
    &.__error {
      color: var(--palette-negative);
    }
    &.__success {
      color: var(--palette-positive);
    }
  }

  &__icon-left,
  &__icon-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
    &.__clickable {
      cursor: pointer;
    }
  }
  &__icon-left {
    left: 0;
  }
  &__icon-right {
    right: 0;
  }
}
