.card-edit {
  &__short-input {
    width: calc(50% - var(--sp-2));
  }
  &__header-address {
    color: var(--palette-foreground);
  }
  &__checkbox {
    justify-content: flex-start;
  }
}
