@import "../../../../../../styles/mixins/media";
@import "../../../../../../styles/mixins/typography";

.appointments-availability {
  width: 100%;

  &__text {
    color: var(--palette-neutral);
  }
  &__text-headline {
    @include body-big;
  }
  &__image {
    max-width: 100%;
  }
  &__link {
    word-break: break-all;
  }
  &__not-verified {
    flex-direction: column;
    min-height: inherit;
  }
  &__time {
    fill: rgba(var(--palette-neutral-rgb), 0.5);
  }
  &__btn {
    width: 100%;
  }
  &__body {
    padding-bottom: 0;
  }
}

@include desktop-up {
  .appointments-availability {
    &__text-headline {
      @include headline-2;
    }
    &__btn {
      width: auto;
    }
    &__body {
      padding-bottom: var(--sp-8);
      &.__with-token {
        padding-bottom: var(--sp-4);
      }
    }
  }
}
