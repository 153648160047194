@import "../../styles/mixins/media";

.tip-modal {
  & > *:last-child {
    max-width: 440px;
  }
  &__wrapper-buttons {
    display: flex;
    justify-content: space-between;
    &-btn {
      width: calc(50% - var(--sp-1));
    }
  }

  &__link {
    color: var(--palette-primary);
    &:hover {
      color: var(--palette-primary-darken-2);
    }
  }
  @include desktop-up {
    &__success-window-description {
      display: block;
      padding: 0 var(--sp-8);
    }
  }
}
