@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/typography";

.performer-video {
  &__image-wrapper {
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      .performer-video__icon-wrapper {
        display: block;
      }
    }
  }

  &__preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__duration {
    position: absolute;
    right: var(--sp-1);
    bottom: var(--sp-1);
    border-radius: 5px;
    backdrop-filter: blur(4px);
    color: var(--palette-white);
    background: var(--palette-foreground);
  }
  &__icon-wrapper {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(var(--palette-foreground-rgb), 0.6);
    backdrop-filter: blur(4px);
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}