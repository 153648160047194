@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/divider";

.navigation-menu-mobile {
  position: relative;

  &__nav {
    display: none;
    position: absolute;
    z-index: 2;
    top: calc(100% + 5px);
    width: 100%;
  }

  &.__active {
    .navigation-menu-mobile__nav {
      display: block;
    }
  }

  &__list {
    padding: var(--sp-3) 0;
    box-shadow: 15px 19px 83px rgba(var(--palette-black-rgb), 0.2);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    padding: var(--sp-3) var(--sp-4) var(--sp-3) var(--sp-12);

    &.__current {
      cursor: pointer;
      background: var(--palette-white);
      height: 50px;
      font-weight: 600;
      padding-left: var(--sp-10);
      margin-bottom: var(--sp-2);
      border-radius: 10px;
      .icon {
        left: 20px;
      }
    }
    &.active {
      color: var(--palette-neutral);
    }

    &-icon {
      position: absolute;
      fill: var(--palette-foreground);
      left: var(--sp-6);
      top: 50%;
      transform: translateY(-50%);
      height: 19px;
      width: 21px;
    }
  }
  &__separator {
    @include divider;
    margin: var(--sp-3) var(--sp-6);
    pointer-events: none;
  }

  &.__without-icons {
    .navigation-menu-mobile__item {
      padding-left: var(--sp-6);

      &.__current {
        padding-left: var(--sp-5);
      }
    }
  }

  &.__outline {
    .navigation-menu-mobile__item {
      font-weight: 600;
      &.__current {
        border: 1px solid rgba(var(--palette-neutral-rgb), 0.5);
        font-weight: 400;
      }
    }
  }

  @include desktop-up {
    display: none;
  }
}
