@import "src/styles/mixins/media";
.performer-verification-documents {
  &__photo-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include portrait-tablet {
      flex-direction: row;
    }
    &-item {
      width: 220px;
      @include portrait-tablet {
      }
      &:not(:last-child) {
        margin-bottom: var(--sp-4);
        @include portrait-tablet {
          margin-bottom: initial;
          margin-right: var(--sp-auto);
        }
      }
    }
  }
}
