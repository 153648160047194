@import "../../../../styles/mixins/media";

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 1px solid var(--palette-primary);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
}

.ripple-avatar-animation {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(305.27deg, var(--palette-foreground) -9.6%, var(--palette-foreground-lighten-1) 110.4%);

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    &::after {
      @include rings(3s, 0s);
    }

    &::before {
      @include rings(3s, 0.5s);
    }
  }

  &__avatar {
    border: 2px solid var(--palette-primary);
  }

  @include desktop-up {
    border-radius: 10px;
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}
