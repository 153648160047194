@import "../../../../styles/mixins/media";

.refill-balance {
  &__card {
    background: var(--palette-primary);
    border-radius: 10px;
    padding-top: var(--sp-7);
    padding-bottom: var(--sp-8);
    color: var(--palette-white);
    margin-bottom: var(--sp-6);
  }

  &__amount {
    width: 100%;
    font-size: 46px;
    margin-bottom: var(--sp-3);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__currency {
    margin-right: var(--sp-4);
  }

  &__cost {
    opacity: 0.7;
  }

  @include portrait-tablet {
    width: 360px;
  }
}
