@mixin headline-1 {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
}

@mixin headline-2 {
  font-size: 19px;
  font-weight: 600;
  line-height: 29px;
}

@mixin body-big {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}

@mixin body-headline {
  font-size: 13px;
  font-weight: 700;
  line-height: 23px;
}

@mixin body {
  font-size: 13px;
  line-height: 23px;
}

@mixin label {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}

@mixin small {
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
}

@mixin tiny {
  font-size: 10px;
  line-height: 1;
}
