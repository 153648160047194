@import "../../../../../styles/mixins/media";

.transaction-item {
  padding: var(--sp-4);
  font-size: 13px;
  line-height: 15px;

  display: flex;
  flex-wrap: wrap;

  &__heading {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: var(--sp-3);
    flex-basis: 70%;
  }

  &__description {
    word-break: break-word;
    margin-bottom: var(--sp-2);

    .__capitalize:first-letter {
      text-transform: uppercase;
    }
  }

  &__amount {
    text-align: right;
    word-break: break-word;
    padding-left: var(--sp-2);
    margin-left: auto;
    flex-basis: 30%;
  }

  &__date {
    font-size: 12px;
    line-height: 16px;
    width: 100%;
  }

  &:nth-child(even) {
    background-color: var(--palette-input-bg);
  }

  @include desktop-up {
    padding: var(--sp-4) var(--sp-6);
    min-height: 82px;
    align-items: center;

    &__container {
      flex-grow: 1;
    }

    &__heading {
      margin-bottom: 0;
      flex-basis: 20%;
    }

    &__description {
      flex-basis: 34%;
      margin-bottom: 0;
    }

    &__duration {
      flex-basis: 13%;
    }

    &__amount {
      order: 1;
    }

    &__amount {
      flex-basis: 17%;
    }

    &__heading,
    &__description,
    &__duration,
    &__date {
      padding-right: var(--sp-2);
    }

    &__date {
      flex-basis: 16%;
      font-weight: 600;
      &-month {
        white-space: nowrap;
        color: var(--palette-neutral);
      }
    }

    &:nth-child(even) {
      background-color: unset;
    }

    &:nth-child(odd) {
      background-color: var(--palette-input-bg);
      border-radius: 10px;
    }
  }
}
