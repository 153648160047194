@import "../../styles/mixins/media";

.page-performer-profile {
  &__header-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  &__back-button {
    flex-shrink: 0;
    padding: 0;
    width: 32px;
    height: 32px;
  }
  &__screen-name-header {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: var(--sp-2);
  }

  &__status-line {
    padding: 0 var(--sp-2);
    margin-bottom: var(--sp-4);
  }

  &__total-reviews {
    color: var(--palette-neutral);
  }

  & &__favorite {
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }

    &:focus {
      opacity: 0.7;
    }
  }

  &__switch-buttons {
    display: flex;
  }

  &__switch-button {
    border-radius: 10px 10px 0 0;
    padding: 17px 25px;
    color: var(--palette-foreground);
    background: transparent;
    width: 50%;
    &:focus {
      box-shadow: none;
    }

    &.__active {
      background: var(--palette-white);
    }
  }

  &__book-show-button {
    position: fixed;
    z-index: 1;
    background: var(--palette-primary);
    border-radius: 50%;
    max-height: 69px;
    max-width: 69px;
    height: 100%;
    width: 100%;
    bottom: 30px;
    left: calc(50% - 34.5px);

    padding: 21px 17px 16px 22px;
    box-shadow: 0 11px 29px 0 rgba(var(--palette-primary-darken-1-rgb), 0.75);
  }

  &__spinner-container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include portrait-tablet {
    &__status-line {
      display: none;
    }

    &__switch-buttons {
      display: none;
    }

    &__profile-card {
      margin-bottom: var(--sp-6);
    }
  }

  @include desktop-up {
    padding: var(--sp-8) 0;
    &__screen-name-header {
      margin-bottom: var(--sp-8);
    }

    &__book-show-button {
      display: none;
    }
  }
}
