.base-button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  font-size: 13px;
  font-weight: 600;
  padding-left: var(--sp-4);
  padding-right: var(--sp-4);

  color: var(--palette-white);
  fill: var(--palette-white);
  background: var(--palette-primary);
  transition: background-color 0.25s linear, border-color 0.25s linear, fill 0.25s linear;

  &:hover,
  &:focus {
    color: var(--palette-white);
    fill: var(--palette-white);
  }

  &:hover {
    background: var(--palette-primary-darken-1);
  }

  &:active {
    background: var(--palette-primary-darken-2);
  }

  &.__opacity {
    color: var(--palette-foreground);
    fill: var(--palette-foreground);
    background: transparent;
    box-shadow: none;

    &:hover {
      color: var(--palette-foreground);
      fill: var(--palette-foreground);
    }
  }

  &.__outline {
    border: 1px solid rgba(var(--palette-neutral-rgb), 0.5);
    background: transparent;
    color: var(--palette-foreground);
    fill: var(--palette-foreground);
    box-shadow: none;
    &:hover {
      border-color: rgba(var(--palette-foreground-rgb), 0.5);
    }
    &:active {
      border-color: var(--palette-primary-darken-2);
    }

    &.__large {
      &:active {
        border-color: var(--palette-primary);
      }
    }
  }

  &.__small {
    border-radius: 5px;
  }

  &.__large {
    height: 50px;
    padding-left: var(--sp-7);
    padding-right: var(--sp-7);
  }

  &.__fluid {
    width: 100%;
  }

  &.__icon {
    width: 40px;
    height: 40px;
    padding: 0;

    &.__small {
      width: 20px;
      height: 20px;
    }

    &.__large {
      width: 50px;
      height: 50px;
    }

    &:hover {
      background: var(--palette-input-bg);
    }
  }

  &.__round {
    border-radius: 1000px;
  }

  &.__negative {
    background-color: var(--palette-negative);
    &:hover {
      background-color: var(--palette-negative-darken-1);
    }
    &:active {
      background-color: var(--palette-negative-darken-2);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--palette-negative-lighten-2);
    }
  }

  &.__borderless {
    color: var(--palette-foreground);
    fill: var(--palette-foreground);
    background-color: var(--palette-white);
    &:hover {
      //сливается с серым фоном
      background-color: var(--palette-input-bg);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--palette-primary-lighten-2);
    }
    &:active {
      box-shadow: inset 0 0 0 1px var(--palette-neutral-lighten-2);
    }
  }

  &.__live {
    background: var(--palette-white);
    color: var(--palette-foreground);
    border: 2px solid var(--palette-primary-lighten-2);
    &:hover {
      border-color: var(--palette-primary);
    }
    &:active {
      border-color: var(--palette-primary);
    }
  }

  &.__disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__children {
    display: flex;
    align-items: center;
    justify-content: center;

    &.__hide {
      visibility: hidden;
    }
  }

  & .base-spinner {
    position: absolute;
  }
}
