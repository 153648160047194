@import "../../../../../../styles/mixins/media";

.appointments-item {
  display: flex;
  flex-wrap: wrap;
  padding: var(--sp-4);
  padding-bottom: var(--sp-5);

  &__date {
    flex-basis: 68%;
    margin-bottom: var(--sp-3);
    &-month {
      color: var(--palette-neutral);
    }
  }

  &__status {
    flex-basis: 32%;
    text-align: right;
    &.__paid {
      color: var(--palette-positive);
      font-weight: 600;
    }
  }

  &__time {
    flex-basis: 100%;
    margin-bottom: var(--sp-3);
  }

  &__name {
    flex-basis: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 14px;
  }

  &:nth-child(even) {
    background-color: var(--palette-input-bg);
  }

  @include desktop-up {
    align-items: center;
    min-height: 82px;
    border-radius: 10px;
    padding-bottom: var(--sp-4);

    &__date,
    &__time {
      flex-basis: 25%;
      margin-bottom: 0;
    }

    &__name {
      flex-basis: 28%;
    }

    &__status {
      display: flex;
      justify-content: flex-end;
      flex-basis: 22%;
      order: 1;
    }
  }
}
