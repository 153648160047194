@import "../../styles/mixins/media";
@import "../../styles/mixins/typography";

.base-textarea {
  margin-bottom: var(--sp-2);
  overflow: hidden;
  textarea {
    overflow-y: scroll;
    resize: none;
    background: none;
    border: none;
    width: 100%;
    font-size: 13px;
    line-height: 23px;
    padding: var(--sp-3) var(--sp-5) var(--sp-5);
    &:focus,
    &:hover,
    &:active {
      outline: none;
    }
  }
  &__container {
    background-color: var(--palette-input-bg);
    border-radius: 10px;
    padding: 0;
    color: var(--palette-foreground);
    &:focus-within {
      box-shadow: inset 0 0 0 1px rgba(var(--palette-primary-rgb), 0.8);
    }
  }
  &__label {
    align-self: flex-start;
    margin-bottom: var(--sp-2);
    @include label;
    color: var(--palette-neutral);
  }
  &__counter {
    padding: var(--sp-4) var(--sp-5) 0;
    &-max {
      color: var(--palette-neutral);
    }
  }
  &.__label {
    .base-textarea__container {
      margin-top: var(--sp-1);
    }
  }
  &.__counter {
    .base-textarea__container {
      padding: 0 0 var(--sp-5) 0;
    }
    textarea {
      padding: var(--sp-3) var(--sp-5) 0;
    }
  }

  &.__error {
    .base-textarea__container {
      box-shadow: inset 0 0 0 1px var(--palette-negative);
    }
  }

  &__error {
    width: 100%;
    text-transform: lowercase;
    margin-top: var(--sp-3);
    color: var(--palette-negative);
    &:first-letter {
      text-transform: uppercase;
    }
  }

  @include portrait-tablet {
    margin-bottom: var(--sp-4);
  }
}
