@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/commonLiveChat";

.stream-preview {
  display: flex;
  background-color: var(--palette-black);
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  max-height: 480px;
  max-width: 100%;
  width: 100%;
  height: 100%;

  &__video-wrapper-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }

  &.__video-disabled {
    width: 640px;
    aspect-ratio: 4 / 3;
    .stream-preview__video {
      opacity: 0;
    }
    .stream-preview__animation {
      display: block;
    }
  }

  &__control-buttons {
    position: absolute;
    z-index: 2;
    bottom: 0;
    padding: var(--sp-3);
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  &__video {
    height: 100%;
    width: 100%;
    &.__mirror-reflection {
      transform: scaleX(-1);
    }
  }

  &__live-user {
    color: var(--palette-white);
    text-shadow: 0 1px 4px rgba(var(--palette-foreground-rgb), 0.7);
    margin-top: 3px;
  }

  @include desktop-up {
    &__shadow {
      position: absolute;
      z-index: 0;
      opacity: 0;
      bottom: 0;
      width: 100%;
      height: 150px;
      background: linear-gradient(
        180deg,
        rgba(var(--palette-neutral-darken-2-rgb), 0) 0%,
        rgba(var(--palette-neutral-darken-2-rgb), 0.3) 100%
      );
      transition: opacity 300ms 1.5s;
    }

    &__control-buttons {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms 1.5s, transform 300ms 1.5s;
    }

    &:hover {
      .stream-preview__control-buttons,
      .stream-preview__live-user,
      .stream-preview__shadow {
        opacity: 1;
        transform: scale(1);
        transition: opacity 300ms 0s, transform 300ms 0s;
      }
    }
  }
}
