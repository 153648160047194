@import "media";

:root {
  --chat-width: 310px;
}
@mixin liveChat {
  position: fixed;
  z-index: 10;
  padding: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  & > * {
    flex-grow: 1;
    height: 100%;
    & > * {
      height: 100%;
    }
  }
  @include desktop-up {
    & > * {
      width: 100%;
      height: unset;
    }
    z-index: 0;
    overflow: hidden;
    max-height: calc(100vh - 80px);
    display: flex;
    position: static;
    padding-top: var(--sp-12);
    padding-bottom: calc(min(120px, 14%));
  }
}

@mixin headerBtn {
  padding: 0;
  width: 24px;
  height: 24px;
  fill: var(--palette-white);
  filter: drop-shadow(0 0.8px 3px rgba(var(--palette-foreground-rgb), 0.6));
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
  &:active {
    background-color: transparent;
  }
  margin-right: var(--sp-3);
  &:last-child {
    margin-right: 0;
  }
  &.__disabled {
    opacity: 1;
  }

  @include desktop-up {
    filter: none;
    cursor: pointer;
    &:not(.__active) {
      fill: var(--palette-neutral);
    }
    &.__active {
      fill: var(--palette-primary);
      pointer-events: none;
    }
  }
}
