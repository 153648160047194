@import "../../../../styles/mixins/commonLiveChat";
@import "../../../../styles/mixins/media";

.public-stream-view {
  &__share-modal {
    z-index: 101;

    .modal-window__content {
      max-width: 440px;
    }

    &-buttons {
      display: flex;
      margin-top: var(--sp-8);
    }

    &-btn {
      width: 50%;
      &:not(:first-child) {
        margin-left: var(--sp-2);
      }
    }
  }

  &__btn {
    @include headerBtn;
    &.__share {
      stroke: var(--palette-white);
      .public-stream-view__btn-icon {
        fill: none;
      }
    }
  }

  @include desktop-up {
    &__btn {
      &.__share {
        stroke: var(--palette-neutral);
        &:hover {
          stroke: var(--palette-primary);
        }
      }
    }
  }
}
