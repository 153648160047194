@import "../../../../../../styles/mixins/media";
@import "../../../../../../styles/mixins/divider";

.appointments-list {
  margin: 0 var(--sp-n6);

  &.__empty,
  &.__hide-mobile-border {
    @include divider-none;
  }

  &__heading {
    display: none;
  }

  &__empty-content {
    width: 100%;
    min-height: 114px;
    color: var(--palette-neutral);
    background: var(--palette-input-bg);
  }

  @include desktop-up {
    margin: 0 var(--sp-n2);

    &.__hide-mobile-border {
      @include divider;
    }

    &__heading {
      display: flex;

      color: var(--palette-neutral);
      padding: 0 var(--sp-4);

      &-date,
      &-time {
        flex-basis: 25%;
      }

      &-status {
        margin-left: auto;
      }
    }

    &__empty-content {
      border-radius: 10px;
      min-height: 82px;
    }
  }
}
