.questionnaire-page {
  &-form {
    &__textarea-label {
      display: block;
      font-weight: 600;
      margin-bottom: var(--sp-3);
      color: var(--palette-foreground);
    }

    &__button {
      margin-left: auto;
      margin-top: var(--sp-4);
    }
  }
}
