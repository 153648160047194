@import "styles/mixins/media";

.video-control-button {
  &__btn {
    width: 40px;
    height: 40px;
    padding: 0;
  }
  &.__borderless {
    opacity: 0.8;
    .video-control-button {
      &__btn {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  &.__on,
  &.__off {
    .video-control-button {
      &__btn {
        --stroke-color: var(--palette-negative);
      }
    }
  }

  @include desktop-up {
    &.__on,
    &.__off {
      .video-control-button {
        &__btn {
          background-color: var(--palette-white);
          transition: fill 0.25s linear;
        }
      }
    }
    &.__on {
      .video-control-button {
        &__btn {
          &:hover {
            fill: var(--palette-negative);
          }
        }
      }
    }
    &.__off {
      .video-control-button {
        &__btn {
          &:hover {
            fill: var(--palette-primary);
            --stroke-color: var(--palette-primary);
          }
        }
      }
    }
  }
}
