.action-result {
  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto var(--sp-4);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--palette-positive);
  }

  &__icon {
    fill: var(--palette-white);
  }

  &.__pending {
    .action-result__icon-container {
      background: var(--palette-yellow);
    }
  }
  &.__failed {
    .action-result__icon-container {
      background: var(--palette-negative);
    }
    .action-result__icon {
      width: 20px;
      height: 20px;
    }
  }
}
