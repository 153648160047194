.base-paginator {
  display: flex;
  user-select: none;
  align-items: center;

  &__pages {
    display: flex;
    align-items: center;
  }

  &__item {
    cursor: pointer;
    font-weight: 600;
    min-width: 35px;
    text-align: center;
    padding: 0 var(--sp-1);

    &.__current {
      color: var(--palette-neutral);
    }

    &:last-of-type {
      padding-right: 0;
    }
    &:first-of-type {
      padding-left: 0;
    }
  }

  &__more-icon {
    fill: var(--palette-neutral);
    min-width: 35px;
  }

  &__back-arrow {
    cursor: pointer;
  }

  &__forward-arrow {
    cursor: pointer;
    transform: rotate(180deg);
  }
}
