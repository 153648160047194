@import "../../styles/mixins/commonJoinForm";

.join-live-chat-modal {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--sp-6);

  &.__mobile-landscape {
    max-width: 320px;
  }
  &__buttons {
    display: flex;
    margin-top: var(--sp-5);
    justify-content: space-between;
    > *:not(:first-child) {
      margin-left: var(--sp-2);
    }
  }
  &__btn {
    width: 50%;
  }

  @include desktop-up {
    max-width: 840px;
    flex-direction: row;
    &__settings {
      max-width: 290px;
    }
  }
}
