.message-tip-item {
  &__content {
    padding: 12px var(--sp-3);
    margin-bottom: var(--sp-1);
    border-radius: 10px;
    min-width: 110px;
    background: rgba(var(--palette-primary-lighten-2-rgb), 0.3);
  }
  &__text {
    color: var(--palette-neutral);
  }
}
