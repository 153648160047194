@import "../../../../styles/mixins/media";

.messages-list {
  display: flex;
  flex-direction: column;
  width: 0;
  flex-grow: 1;
  padding: 0;

  &:hover {
    box-shadow: none;
  }
  &__heading {
    display: none;
  }

  &__empty-text {
    color: var(--palette-neutral);
  }

  @include desktop-up {
    border-radius: 0 10px 10px 0;
    &__heading {
      display: block;
      padding: var(--sp-8) var(--sp-8) var(--sp-4);
      border-bottom: 1px solid rgba(var(--palette-neutral-lighten-2-rgb), 0.45);
      position: relative;
      &-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
}
