.base-avatar {
  border-radius: 50%;
  overflow: hidden;
  background: var(--palette-input-bg);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &__avatar {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
