@import "../../styles/mixins/media";

.message-input {
  border-top: 1px solid rgba(var(--palette-neutral-lighten-2-rgb), 0.45);
  display: flex;
  align-items: flex-start;
  padding: var(--sp-2);
  margin-top: auto;
  background-color: var(--palette-white);

  &__input-wrapper {
    margin-bottom: 0;
    margin-right: var(--sp-2);
    padding-top: var(--sp-2);
    flex-grow: 1;
    width: 0;
  }

  &__input {
    margin-bottom: 0;
    & > * {
      background-color: transparent;
      &:focus-within {
        box-shadow: none;
      }

      textarea {
        position: relative;
        padding: 0;
        font-size: 12px;
        height: 17px;
        /* ios opening keyboard issue fix */
        &::placeholder {
          position: absolute;
          z-index: 1;
        }
        &:focus::placeholder {
          color: var(--palette-neutral-lighten-2);
        }
      }
    }
  }

  &__error {
    display: inline-flex;
    margin-bottom: var(--sp-3);
  }

  &__error-message {
    color: var(--palette-negative);
  }

  &__error-close {
    margin-top: var(--sp-n1);
    margin-right: var(--sp-n4);
  }

  &.__transparent {
    background-color: transparent;
    color: var(--palette-white);
    button {
      &:hover {
        background-color: rgba(var(--palette-white-rgb), 0.3);
      }
      & > * {
        fill: var(--palette-white);
      }
    }
    textarea {
      color: var(--palette-white);
    }
  }

  @include desktop-up {
    padding: var(--sp-1) var(--sp-4) var(--sp-2);
  }
}
