.infinite-scroll {
  position: relative;
  overflow-anchor: auto;
  &__button {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
