@import "../../../../../../styles/mixins/media";
@import "../../../../../../styles/mixins/divider";
@import "../../../../../../styles/headline";

.review-item {
  position: relative;
  padding: var(--sp-6);
  padding-top: var(--sp-13);

  @include divider;
  &:nth-last-child(2) {
    @include divider-none;
  }

  &__info {
    margin-top: var(--sp-4);
  }

  &__name {
    @include body;
    line-height: 17px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    word-break: break-word;
  }

  &__rating {
    position: absolute;
    top: var(--sp-5);
    left: var(--sp-6);
  }

  &__time {
    color: var(--palette-neutral);
  }

  @include desktop-up {
    display: flex;
    @include divider-none;
    border-radius: 10px;
    border: 1px solid var(--palette-neutral-lighten-2);
    padding: var(--sp-8);
    margin-bottom: var(--sp-4);

    &:nth-last-child(2) {
      border-bottom: 1px solid var(--palette-neutral-lighten-2);
    }

    &__info {
      order: -1;
      margin-top: 0;
      margin-right: var(--sp-9);
      max-width: 140px;
    }

    &__rating {
      position: relative;
      top: 0;
      left: 0;
    }

    &__name {
      line-height: 17px;
    }
  }
}
