@import "../../../../styles/mixins/media";

.payment-packages {
  display: grid;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-gap: var(--sp-4);

  @include portrait-mobile {
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
  }

  @include portrait-tablet {
    grid-template-columns: repeat(4, minmax(100px, 160px));
  }
}
