.filter-by-name {
  input {
    background: var(--palette-white);
  }

  &__icon-right .icon {
    width: 13px;
    height: 13px;
    fill: var(--palette-neutral);
  }
}
