@import "styles/mixins/commonLiveChat";
.page-conference-chat {
  @include liveChat;
  @include desktop-up {
    max-height: 100vh;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
}
