.chat-timer {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  padding: 0 var(--sp-2);
  height: 25px;
  background-color: var(--palette-white);
  border-radius: 5px;
  &:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: var(--palette-negative);
    border-radius: 50%;
  }

  &.__gray-circle {
    &:before {
      background-color: var(--palette-neutral);
    }
  }
}
