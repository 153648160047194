@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/divider";

.filters {
  margin-bottom: var(--sp-4);
  display: flex;

  &__by-name {
    flex-grow: 1;
    margin-right: var(--sp-1);
  }

  &-mobile {
    background: var(--palette-white);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    max-width: 335px;
    width: 100%;
    z-index: 10;
    transform: translateX(-100%);
    transition: transform 0.25s linear;
    overflow: auto;

    &.__active {
      box-shadow: 0 10px 35px rgba(var(--palette-foreground-rgb), 0.2);
      transform: translateX(0);
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 0 var(--sp-6);
      height: 85px;
      border-bottom: 1px solid rgba(var(--palette-neutral-lighten-2-rgb), 0.45);

      position: sticky;
      z-index: 1;
      top: 0;
      background: var(--palette-white);
      & > *:not(:last-child) {
        margin-right: var(--sp-4);
      }

      &-wrapper-icon {
        position: relative;
      }
      &-counter {
        position: absolute;
        z-index: 1;
        bottom: calc(100% - 8px);
        left: calc(100% - 8px);
      }
      &-result {
        display: flex;
        align-items: baseline;
        width: 0;
        flex-grow: 1;
      }
    }

    &__close {
      padding: 0;
      height: auto;
      margin-left: auto;
    }

    &__heading {
      font-size: 18px;
      line-height: 16px;
      font-weight: 600;
    }

    &__result-line {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--palette-neutral);
    }

    &__main {
      padding: var(--sp-7) var(--sp-6);
      > *:not(:last-child) {
        &:after {
          content: " ";
          display: block;
          width: 100%;
          margin: var(--sp-5) 0;
          @include divider;
        }
      }
    }
  }

  &__activator-button {
    position: relative;
    background: var(--palette-white);
    width: 50px;
    height: 50px;
  }
  &__activator-icon {
    position: absolute;
    fill: var(--palette-foreground);
  }
  &__activator-counter {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
  }

  @include desktop-up {
    flex-direction: column;

    &__by-name {
      margin-right: 0;
      margin-bottom: var(--sp-6);
    }

    &-mobile {
      background: none;
      position: unset;
      transform: unset;
      max-width: unset;
      transition: unset;
      overflow: unset;
      &.__active {
        box-shadow: none;
      }

      &__main {
        padding: 0;
        > *:not(:last-child) {
          margin-bottom: var(--sp-6);
          &:after {
            content: "";
            display: none;
          }
        }
      }
    }
  }
}
