@import "../../styles/mixins/media";

.calendly-button {
  position: relative;
}

.calendly-overlay {
  div.calendly-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    min-width: initial;
    min-height: initial;
    max-width: initial;
    max-height: initial;
    width: 100%;
    height: calc(100% - var(--mobile-header-height));
    .calendly-popup-content {
      top: var(--mobile-header-height);
      width: 100%;
      &.calendly-mobile {
        overflow-y: initial;
      }
    }
  }
  @include desktop-up {
    div.calendly-popup {
      .calendly-popup-content {
        top: 0;
      }
    }
  }
}
