@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/typography";

.performer-book {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.25s linear, box-shadow 0.25s ease-in-out;

  &.__hide {
    transform: translateY(100%);
  }
  &:not(.__hide) {
    .performer-book__inner {
      position: relative;
      border-radius: 15px 15px 0 0;
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 15px 15px 0 0;
        box-shadow: 35px -25px 80px 0 rgba(var(--palette-black-rgb), 0.2);
        @include desktop-up {
          display: none;
        }
      }
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &__close {
    position: absolute;
    top: var(--sp-4);
    right: var(--sp-4);
    padding: 0;
    background: none;
    fill: var(--palette-foreground);
  }

  &__content {
    position: relative;
  }

  &__services {
    overflow: hidden;
    &.__bot-shadow {
      overflow: auto;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background: linear-gradient(
          180deg,
          rgba(var(--palette-white-rgb), 0) 0%,
          rgba(var(--palette-white-rgb), 0.5) 22%
        );
      }
    }
  }

  &__service-item {
    display: flex;
    flex-wrap: wrap;
    &:not(:last-child) {
      padding-bottom: var(--sp-3);
      margin-bottom: var(--sp-3);
    }

    &-heading {
      width: 0;
      flex-grow: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-duration {
      width: 100%;
      color: var(--palette-neutral);
    }
  }

  &__ask-button {
    position: relative;
  }

  @include desktop-up {
    max-width: 320px;
    width: 100%;
    height: 100%;
    position: static;
    transition: box-shadow 0.25s ease-in-out;

    &.__hide {
      transform: none;
    }

    &__backdrop {
      display: none;
    }

    &__inner {
      border-radius: 10px;
      box-shadow: unset;
    }
    &.__show {
      box-shadow: none;
      transform: none;
    }

    &__close {
      display: none;
    }
  }
}
