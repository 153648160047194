@import "../../../../../../styles/mixins/typography";

.sync-button {
  &__btn {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  &__icon {
    fill: var(--palette-neutral-darken-2);

    &.__syncing {
      animation: 2s sync-icon-rotate linear infinite;
    }
  }

  &__caption {
    max-width: 165px;
    margin-left: var(--sp-2);
    color: var(--palette-neutral-darken-2);
    @include tiny;
    line-height: 1.2;
  }

}

@keyframes sync-icon-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
