.users-list-item {
  display: flex;
  align-items: center;
  width: calc(100% - var(--sp-4));
  padding: var(--sp-2) 0;
  margin-left: auto;
  border-bottom: 0.3px solid var(--palette-neutral-lighten-1);
  &__avatar {
    margin-left: var(--sp-n4);
    margin-right: var(--sp-2);
  }
  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
  }
  &__icon-btn {
    flex-shrink: 0;
  }
}
