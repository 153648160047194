@import "../../styles/mixins/typography";

.base-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: var(--sp-1);
  overflow: hidden;
  background-color: var(--palette-input-bg);
  &__image {
    display: block;
    height: inherit;
    width: inherit;
    object-fit: cover;
  }
  &__icon {
    display: block;
    fill: var(--palette-neutral);
  }
  &__title {
    color: var(--palette-foreground);
    @include small;
  }
}
