@import "../../../../styles/mixins/media";

.play-stream-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(305.27deg, var(--palette-foreground) -9.6%, var(--palette-foreground-lighten-1) 110.4%);
  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
