@import "../../../../../../styles/mixins/media";

.users-list {
  flex-direction: column;
  position: relative;
  background-color: var(--palette-white);

  &__header {
    display: flex;
    align-items: center;
    padding: var(--sp-4);
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: var(--sp-n9);
    right: var(--sp-2);
    @include mobile-landscape {
      top: 8px;
    }
  }

  &__guests {
    margin: 0 var(--sp-1);
    font-style: italic;
    color: var(--palette-neutral);
  }

  &__search {
    padding: 0 var(--sp-4);
  }

  &__list {
    overflow: auto;
  }

  &__list-container {
    padding: 0 var(--sp-4);
  }

  @include desktop-up {
    padding-bottom: var(--sp-4);

    &__close {
      top: var(--sp-2);
    }
  }
}
