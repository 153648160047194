@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/typography";

.promote-performer-verification {
  width: 100%;
  max-height: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--sp-1) var(--sp-6);
  margin-bottom: var(--sp-4);
  cursor: pointer;
  @include body-big;
  white-space: initial;
  height: auto;
  > *:not(:last-child) {
    margin-right: var(--sp-1);
  }
  &.__waiting {
    background-color: var(--palette-yellow);
    flex-direction: column;
    cursor: unset;
    padding-top: var(--sp-4);
    padding-bottom: var(--sp-5);
    > *:not(:last-child) {
      margin-right: unset;
      margin-bottom: var(--sp-2);
    }
  }

  @include desktop-up {
    max-height: unset;
    padding: var(--sp-5) var(--sp-6) var(--sp-6)
  }
}
