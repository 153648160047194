@import "../../styles/mixins/media";
@import "../../styles/mixins/commonLiveChat";

.stream-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: calc(env(safe-area-inset-left));
  padding-right: calc(env(safe-area-inset-right));
  padding-bottom: calc(env(safe-area-inset-bottom));
  background-color: var(--palette-black);

  &__left-side {
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
  }

  &__right-side {
    &.__users-mode {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      height: calc(100% - 53px);
      &.__landscape-mode {
        width: 100%;
        height: 100%;
      }
    }
    &.__landscape-mode {
      width: 54%;
      height: unset;
    }
    &.__modal-mode {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      height: 78%;
      border-radius: 10px 10px 0 0;
      overflow: hidden;

      &:not(.__users-mode, .__chat-mode) {
        display: none;
      }

      &.__users-mode {
        .users-list__close {
          top: var(--sp-2);
          right: var(--sp-2);
        }
      }

      .text-chat {
        display: none;
        max-height: 100%;
      }

      &.__chat-mode {
        .text-chat {
          display: flex;
          height: 100%;
          background-color: var(--palette-white);
        }
      }
    }
  }

  &__header {
    position: absolute;
    z-index: 1;
    left: 0;
    padding: var(--sp-3) var(--sp-4);
    width: 100%;
    &.__users-mode {
      padding-right: var(--sp-11);
      background-color: var(--palette-white);
      box-shadow: 0 0.5px 0 rgba(var(--palette-foreground-rgb), 0.2);
      .stream-view {
        &__header {
          &-btn {
            display: none;
          }
          &-bubble {
            margin-top: 0;
            background-color: var(--palette-main-bg);
          }
        }
      }
    }
    &-inner {
      display: flex;
      justify-content: space-between;
    }
    &-buttons {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    &-btn {
      @include headerBtn;
    }
    &-bubble {
      display: flex;
      align-items: center;
      justify-items: center;
      padding: 0 var(--sp-2);
      height: 25px;
      font-size: 11px;
      background-color: var(--palette-white);
      border-radius: var(--sp-1);
    }
  }

  &__video-wrapper {
    background-color: var(--palette-black);
    width: 100%;
    &-header {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 2;
      display: flex;
      justify-content: space-between;
    }

    &.__video-disabled {
      .stream-view__video {
        opacity: 0;
      }
      .stream-view__animation {
        display: block;
      }
    }
  }
  &__control-buttons {
    position: absolute;
    right: var(--sp-3);
    bottom: 90px;
    z-index: 2;
    &.additional-controls {
      right: unset;
      left: var(--sp-3);
    }
  }

  &.__modal-chat {
    .stream-view__control-buttons {
      bottom: var(--sp-4);
    }
  }

  &__muted-notify {
    position: absolute;
    z-index: 1;
    top: var(--sp-3);
    right: var(--sp-3);
    display: flex;
    align-items: center;
    padding: var(--sp-05) var(--sp-2) var(--sp-1) var(--sp-1);
    font-size: 10px;
    font-weight: 400;
    color: var(--palette-white);
    fill: var(--palette-white);
    background-color: rgba(var(--palette-neutral-darken-2-rgb), 0.6);
    border-radius: var(--sp-1);
    pointer-events: none;
    transition: top 0.1s ease-in-out;

    @include desktop-up {
      top: var(--sp-3);
      right: var(--sp-3);
    }
  }

  &__muted-ico {
    margin-right: var(--sp-05);
  }

  &__video {
    background-color: transparent;
    height: 100%;
    width: 100%;
    object-fit: contain;
    &.__mirror-reflection {
      transform: scaleX(-1);
    }
  }

  &__animation {
    display: none;
  }

  &__confirm-exit {
    z-index: 101;

    .modal-window__content {
      max-width: 440px;
    }

    &-buttons {
      display: flex;
      margin-top: var(--sp-6);
    }

    &-btn {
      width: 50%;
      &:not(:first-child) {
        margin-left: var(--sp-2);
      }
    }
  }

  &__live-user {
    display: flex;
    position: absolute;
    z-index: 2;
    left: var(--sp-3);
    top: var(--sp-3);

    &-avatar {
      margin-right: var(--sp-2);
    }

    &-name {
      color: var(--palette-white);
      text-shadow: 0 1px 4px rgba(var(--palette-foreground-rgb), 0.7);
      margin-top: 3px;
    }
  }

  &.__landscape-mode {
    .stream-view__control-buttons {
      left: var(--sp-4);
      bottom: var(--sp-4);
    }
    .stream-view__video-wrapper {
      width: unset;
    }
    .stream-view__header,
    .stream-view__video-wrapper-header,
    .stream-view__animation {
      width: calc(100% - var(--chat-width));
    }
  }

  &.__full-left-side {
    width: 100%;
    .stream-view__left-side {
      flex: 1;
    }
    .stream-view__right-side {
      flex: 0;
    }
  }

  @include desktop-up {
    --right-panel-width: 380px;
    flex-direction: row;
    background-color: transparent;

    &__left-side {
      max-width: calc(100% - var(--right-panel-width) - var(--sp-12));
      margin-right: var(--sp-12);
      height: unset;
      flex-direction: column;
      box-shadow: none;
    }

    &__right-side {
      display: flex;
      flex-direction: row-reverse;
      min-width: var(--right-panel-width);
      width: 100%;
      height: unset;
      &.__users-mode {
        position: static;
        height: 100%;
      }
    }

    &__header {
      position: static;
      display: flex;
      margin-bottom: var(--sp-3);
      padding: 0;
      &-inner {
        display: flex;
        width: 100%;
      }
      &-buttons {
        margin-left: auto;
      }
      &-icon {
        cursor: pointer;
        &:hover {
          fill: var(--palette-primary);
        }
      }
    }

    &__shadow {
      position: absolute;
      z-index: 0;
      opacity: 0;
      bottom: 0;
      width: 100%;
      height: 150px;
      background: linear-gradient(
        180deg,
        rgba(var(--palette-neutral-darken-2-rgb), 0) 0%,
        rgba(var(--palette-neutral-darken-2-rgb), 0.3) 100%
      );
      transition: opacity 300ms 1.5s;
    }

    &__control-buttons {
      left: 0;
      bottom: 0;
      padding: var(--sp-3);
    }

    &__control-buttons,
    &__live-user {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms 1.5s, transform 300ms 1.5s;
    }

    &__video-wrapper {
      display: flex;
      justify-content: center;
      min-width: 480px;
      height: 100%;
      overflow: hidden;
      flex-grow: 1;
      border-radius: 10px;
      position: relative;
      background-color: var(--palette-white);
      &:hover {
        .stream-view__control-buttons,
        .stream-view__live-user,
        .stream-view__shadow {
          opacity: 1;
          transform: scale(1);
          transition: opacity 300ms 0s, transform 300ms 0s;
        }
      }
    }

    &__video {
      position: relative;
      z-index: 1;
      width: unset;
    }
  }
}
