@import "../../../../styles/mixins/media";
@import "../../../../styles/mixins/typography";

.performer-video-modal {
  padding: 0;

  .modal-window__content {
    position: unset;
    background: transparent;
    overflow: hidden;
    padding: 0;
    border-radius: 0;
    min-width: auto;
  }

  .modal-window__close {
    fill: var(--palette-white);
    background: none;
    z-index: 1;
    &:hover {
      background: rgba(var(--palette-foreground-rgb), 0.5);
    }
  }

  &__wrapper {
    max-width: 996px;
  }
  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      .performer-video-modal__icon-wrapper {
        display: block;
      }
    }
  }

  &__image, &__video {
    position: relative;
    max-width: 100%;
    max-height: 100vh;
  }

  &__duration {
    position: absolute;
    right: var(--sp-1);
    bottom: var(--sp-1);
    border-radius: 5px;
    backdrop-filter: blur(4px);
    color: var(--palette-white);
    background: var(--palette-foreground);
  }
  &__icon-wrapper {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(var(--palette-foreground-rgb), 0.6);
    backdrop-filter: blur(4px);
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

