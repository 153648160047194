@import "../../../../../styles/mixins/media";

.default-message {
  &__text {
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__content {
    display: inline-block;
    max-width: 100%;
    font-size: 12px;
    line-height: 17px;
    background: var(--palette-main-bg);
    padding: 12px var(--sp-3);
    border-radius: 10px;
    word-break: break-word;
    margin-bottom: var(--sp-1);
  }

  &__attach {
    max-width: 50vw;
    margin-top: var(--sp-1);
  }

  &.__highlight-message {
    .default-message {
      &__content {
        background: var(--palette-positive-bg);
      }
    }
  }

  &.__mobile-live-message {
    .default-message {
      &__content {
        padding: 0;
        background: none;
        color: var(--palette-white);
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 0;
      }
      &__attach {
        margin-top: 0;
        & > * {
          color: var(--palette-white);
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
  }

  @include desktop-up {
    &__attach {
      max-width: 30vw;
    }
  }
}
