@import "../../styles/mixins/media";

.leave-review-modal {
  &__success {
    max-width: 320px;
  }

  &__buttons {
    justify-content: flex-end;
    margin-top: var(--sp-6);
  }

  &__btn {
    &:not(:last-child) {
      margin-right: var(--sp-2);
      width: calc(50% - var(--sp-1));
    }
  }

  &__textarea {
    margin-bottom: var(--sp-0);
    textarea {
      overflow: auto;
    }
  }

  @include desktop-up {
    &__form {
      width: 480px;
    }

    &__buttons {
      margin-top: var(--sp-8);
    }

    &__btn {
      &:not(:last-child) {
        margin-right: var(--sp-4);
        width: auto;
      }
    }
  }
}
