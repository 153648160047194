@import "../../styles/mixins/media";

.base-page {
  padding: var(--sp-6) 0;
  padding-bottom: var(--sp-12);
  flex: 1 0 auto;
  min-height: 0;

  &.__reverse {
    @include desktop-up {
      .base-page {
        &__inner {
          flex-direction: row-reverse;
        }
        &__side {
          margin: 0 0 0 var(--sp-16);
        }
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__side {
    flex: 1 0 auto;
  }

  &__content {
    min-width: 0;
    flex: 1 1 auto;
  }

  .base-page__loading {
    position: fixed;
    z-index: 9;
  }

  @include desktop-up {
    padding: var(--sp-10) 0;
    padding-bottom: 120px;
    &__inner {
      flex-direction: row;
    }

    &__side {
      margin: 0 var(--sp-16) 0 0;
      max-width: 320px;
      width: 320px;
    }
  }
}
