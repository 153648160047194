@import "../../styles/mixins/commonJoinForm";
.client-login {
  @include joinForm;
  &__forgot-password {
    font-size: inherit;
  }

  &__login {
    width: 110px;
  }
}
