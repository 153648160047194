@import "../../../../../../styles/mixins/media";

.text-chat {
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 0;
  max-height: 300px;

  &__message {
    max-width: calc(100% - 80px);
  }

  &.__opaque {
    .text-chat__message-list {
      mask-image: unset;
    }
  }

  &__message-list {
    mask-image: linear-gradient(transparent, var(--palette-white) 15%);
    padding: var(--sp-3);
    padding-bottom: var(--sp-4);
  }

  &__message-input {
    margin-top: unset;
    padding-left: var(--sp-2);
    padding-right: var(--sp-2);
    .base-menu__list {
      left: var(--sp-3);
    }
  }

  &.__landscape-mode {
    bottom: 0;
    right: 0;
    max-height: 100%;
    width: var(--chat-width);
    .text-chat {
      &__message {
        max-width: calc(100% - var(--sp-6));
      }
      &__message-list {
        mask-image: none;
        width: 100%;
      }
    }
    &:before {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: var(--chat-width);
      background: rgba(var(--palette-foreground-rgb), 0.85);
    }
  }

  @include desktop-up {
    position: static;
    max-height: unset;
    background-color: var(--palette-white);
    &__message-input {
      border-radius: 0 0 var(--sp-2) var(--sp-2);
      .base-menu__list {
        left: 0;
      }
    }
    &__message-list {
      width: 100%;
      mask-image: unset;
      margin-bottom: 0;
    }
  }
}
