.message-thread-item {
  display: flex;
  align-items: center;
  padding: var(--sp-2);
  border-radius: 10px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }

  &:active,
  &:hover,
  &.active {
    background-color: var(--palette-main-bg);
    color: var(--palette-foreground);
  }
  &:focus {
    color: var(--palette-foreground);
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 0;
    flex-grow: 1;
  }

  &__counter {
    margin-left: auto;
  }
}
.message-thread__link {
  display: block;
}
