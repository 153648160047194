@import "styles/mixins/media";

.conference-stream-view {
  width: 100%;
  height: 100%;
  background: var(--palette-foreground);

  &__users-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  &__users {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    gap: var(--sp-2);
    padding: var(--sp-4);
    height: 100%;
  }

  &.__big {
    .conference-stream-view__users {
      display: grid;
      grid-auto-columns: 1fr;
      align-content: center;
    }
  }

  &.__medium,
  &.__small {
    .conference-stream-view__users {
      align-items: flex-start;
    }
    .conference-stream-view__user {
      flex: 0 0 calc(100% / 2 - 5px);
    }
  }

  &.__focused {
    .conference-stream-view__users-wrapper {
      display: none;
    }
    .conference-stream-view__selected-user {
      aspect-ratio: unset;
      border-radius: unset;
      &.__disabled-video {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include desktop-up {
    height: 630px;
    .conference-stream-view__users {
      min-height: 630px;
    }
    &.__big {
      .conference-stream-view__users {
        justify-items: center;
        align-content: center;
      }
      .conference-stream-view__user {
        height: 290px;
      }
    }
    &.__medium {
      .conference-stream-view__users {
        align-content: center;
      }
      .conference-stream-view__user {
        flex: 0 0 calc(100% / 3 - 7px);
      }
    }
    &.__small {
      .conference-stream-view__users {
        align-content: center;
      }
      .conference-stream-view__user {
        flex: 0 0 calc(100% / 4 - 8px);
      }
    }
    &.__focused {
      flex-direction: column;
      .conference-stream-view__selected-user {
        flex: 1 0 490px;
      }
      .conference-stream-view__users-wrapper {
        display: block;
        max-width: 100%;
        flex: 1 0 140px;
      }
      .conference-stream-view__users {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        min-height: unset;
        min-width: 100%;
      }
      .conference-stream-view__user {
        flex: unset;
        flex-shrink: 0;
        height: 100px;
      }
    }
  }
}
