@import "styles/mixins/media";

.conference-user-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: linear-gradient(305.27deg, var(--palette-foreground) -9.6%, var(--palette-foreground-lighten-1) 110.4%);
  aspect-ratio: 26 / 19;

  &__video {
    object-fit: contain;
    display: block;
    height: 100%;
    width: 100%;
  }

  &__screen-name,
  &__muted {
    position: absolute;
    bottom: var(--sp-1);
    color: var(--palette-white);
    background: rgba(var(--palette-foreground-rgb), 0.7);
  }

  &__screen-name {
    left: var(--sp-1);
    border-radius: 4px;
    max-width: 80%;
  }

  &__muted {
    border-radius: 100px;
    fill: var(--palette-white);
    right: var(--sp-1);
    padding: 2px;
  }

  &__muted-icon {
    fill: var(--palette-white);
    display: block;
  }

  &__animation {
    z-index: 1;
  }

  &.__disabled-video {
    .conference-user-item__video {
      display: none;
    }
  }

  &.__mirror-reflection {
    .conference-user-item__video {
      transform: unset;
    }
  }
}
