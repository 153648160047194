.base-spinner {
  position: relative;
  width: 32px;
  height: 32px;
  &__gradient-start {
    stop-color: var(--palette-foreground);
  }
  &__gradient-end {
    stop-color: var(--palette-main-bg);
  }
  &__image {
    display: block;
    width: 100%;
    height: 100%;
  }
  &.__infinite {
    animation: 1s linear base-spinner-rotate infinite;
  }
  svg {
    overflow: initial;
  }
}
@keyframes base-spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
