.change-password-set {
  &__header {
    text-align: center;
    margin-bottom: var(--sp-4);
  }

  &__buttons {
    display: flex;
    > *:not(:first-child) {
      margin-left: var(--sp-2);
    }
  }
}
