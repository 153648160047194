@import "../../../../../styles/mixins/typography";

.default-message-view {
  display: flex;
  margin-bottom: var(--sp-4);

  &:nth-of-type(3) {
    margin-bottom: 0;
  }

  &__avatar {
    margin-right: var(--sp-1);
    &.__empty {
      opacity: 0;
    }
  }

  &__container {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  &__name {
    @include small;
    line-height: 14px;
    color: rgba(var(--palette-neutral-rgb), 0.8);
    margin-bottom: var(--sp-1);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  &__time {
    color: var(--palette-neutral);
  }

  &.__self-message:not(.__mobile-live-message) {
    align-self: flex-end;
    flex-direction: row-reverse;

    .default-message-view {
      &__avatar {
        margin-right: 0;
        margin-left: var(--sp-1);
      }
      &__container {
        align-items: flex-end;
      }
      &__time,
      &__name {
        text-align: right;
      }
    }
  }

  &.__highlight-message {
    .default-message-view {
      &__name {
        font-weight: 600;
        color: var(--palette-positive);
      }
    }
  }

  &.__compact-message {
    margin-bottom: 0;
  }

  &.__mobile-live-message {
    .default-message-view {
      &__name {
        font-weight: 600;
        margin-bottom: 0;
      }
      &__time {
        display: none;
      }
    }
    &.__highlight-message {
      .default-message-view {
        &__name {
          color: var(--palette-primary);
        }
        &__avatar {
          border: 2px solid var(--palette-primary);
        }
      }
    }
    &:not(.__compact-message) {
      margin-bottom: var(--sp-2);
    }
  }
}
