@import "styles/mixins/commonJoinForm";

.conference-share-modal {
  max-width: 440px;
  margin: 0 auto;

  &__buttons {
    display: flex;
    gap: var(--sp-2);
    margin-top: var(--sp-5);
    justify-content: space-between;
  }
  &__btn {
    width: 50%;
  }
}
