@import "styles/mixins/media";

.conference-view {
  .stream-view__shadow {
    display: none;
  }

  &__void {
   display: none;
  }

  &__grid {
    order: 1;
  }

  @include desktop-up {
    .stream-view__video-wrapper {
      min-height: 630px;
    }
    &__dot-animation {
      min-height: 630px;
    }
    &__void {
      order: -1;
      display: block;
      margin-right: auto;
      margin-left: 40px;
    }

    &__grid {
      margin-left: auto;
    }
  }
}
