@import "../../styles/mixins/typography.scss";
@import "../../styles/mixins/media.scss";

.notification {
  position: fixed;
  top: 90px;
  right: 20px;
  left: 20px;
  z-index: 102;
  display: flex;
  padding: var(--sp-4) var(--sp-6) var(--sp-5) var(--sp-4);
  background-color: var(--palette-white);
  box-shadow: 15px 19px 83px rgba(12, 19, 26, 0.191352);
  border-radius: 10px;
  cursor: pointer;

  @include portrait-mobile {
    width: 100%;
    max-width: 315px;
    left: auto;
  }

  &__wrapper {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &__avatar,
  &__icon {
    margin-right: var(--sp-2);
  }

  & &__close {
    border-radius: 5px;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 6px;
    right: 6px;
  }

  &__text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    @include body;
  }

  &__author {
    @include body-headline;
  }

  &__time {
    margin-left: auto;
    color: var(--palette-neutral);
    @include label;
  }
}

.slide-right {
  &-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: 0.2s ease-in-out;
  }

  &-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: 0.1s ease-in-out;
  }
}
