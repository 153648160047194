@import "../../../../styles/mixins/media";

.payment-package-card {
  display: block;
  user-select: none;

  cursor: pointer;
  padding: var(--sp-6) var(--sp-1);
  border-radius: 10px;
  border: 1px solid rgba(var(--palette-neutral-rgb), 0.5);
  transition: box-shadow 0.25s ease-in-out, border-color 0.25s ease-in-out;

  &__name {
    margin-bottom: var(--sp-3);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    height: 26px;
  }

  &__amount {
    margin-bottom: var(--sp-1);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__currency {
    margin-bottom: var(--sp-4);
  }

  &__amount {
    font-weight: 600;
  }

  &__cost {
    color: var(--palette-neutral);
  }

  &.__active {
    box-shadow: 15px 19px 83px rgba(var(--palette-black-rgb), 0.2),
      inset 0 0 0 1px var(--palette-primary-lighten-2);
    border-color: var(--palette-primary-lighten-2);
  }

  @include desktop-up {
    &:hover:not(.__active) {
      box-shadow: 15px 19px 83px rgba(var(--palette-black-rgb), 0.2);
      border-color: transparent;
    }
  }
}
