.dot-flashing {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(305.27deg, var(--palette-foreground) -9.6%, var(--palette-foreground-lighten-1) 110.4%);
  width: 100%;
  height: 100%;

  &__text {
    color: var(--palette-neutral-lighten-2);
  }

  &__animation {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--palette-primary);
    margin-right: var(--sp-2);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &.__left {
      animation-delay: 0s;
    }
    &.__right {
      animation-delay: 1s;
    }
    &:last-child {
      margin: 0;
    }
  }
}

@keyframes dotFlashing {
  0% {
    background: var(--palette-primary);
    box-shadow: 0 0 0 1px var(--palette-primary);
  }
  50%,
  100% {
    background: var(--palette-neutral-lighten-2);
  }
}
