.element-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  &:before {
    background-color: var(--palette-white);
    width: 100%;
    height: 100%;
    content: " ";
    opacity: 0.7;
    position: absolute;
  }
}
