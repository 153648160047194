@import "../../styles/mixins/typography";

.file-attached {
  display: block;

  &.__error {
    display: flex;
    align-items: center;
    color: var(--palette-negative);
  }

  &__image {
    max-width: 144px;
    height: 72px;
    object-fit: cover;
    border-radius: 5px;
  }

  &__info {
    @include label;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
  }

  &__type {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 16px;
    padding: 0 var(--sp-1);
    font-size: 9px;
    font-weight: 600;
    color: var(--palette-white);
    border-radius: 8px;
    background: var(--palette-neutral);
    text-transform: uppercase;
    cursor: default;
    &.__doc {
      background: #37A8FF;
    }
    &.__image {
      background: #43AC64;
    }
    &.__video {
      background: #23272C;
    }
    &.__audio {
      background: #FF8A18;
    }
    &.__document {
      background: #FF5050;
    }
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__size {
    flex-shrink: 0;
    color: var(--palette-neutral);
    white-space: nowrap;
  }
  &__progress {
    flex-shrink: 0;
    width: 60px;
    height: 8px;
    border-radius: 4px;
    transition: background-size 0.2s ease-in;
    background-repeat: no-repeat;
    background-color: var(--palette-input-bg);
    background-image: linear-gradient(0deg, var(--palette-primary), var(--palette-primary));
  }

  &__remove {
    margin-right: var(--sp-n2);
    margin-left: var(--sp-1);
  }
}
