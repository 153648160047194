@import "../../../../styles/mixins/media";

.second-stream-view {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  left: var(--sp-4);
  top: var(--sp-12);
  border-radius: 10px;
  box-shadow: 0 0 0 1xpx rgba(var(--palette-neutral-rgb), 0.5);
  max-width: 133px;
  max-height: 133px;

  &__video {
    object-fit: contain;
    display: block;
    height: 100%;
    width: 100%;
    transform: scaleX(-1);
  }

  &__animation {
    z-index: 1;
  }

  &.__disabled-video {
    display: none;
  }

  &.__mirror-reflection {
    .second-stream-view__video {
      transform: unset;
    }
  }

  @include desktop-up {
    left: var(--sp-3);
    bottom: var(--sp-3);
    top: unset;
    max-width: 242px;
    max-height: 142px;
  }
}
