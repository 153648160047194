@import "./spacing";
@import "./headline";
@import "./mixins/divider";

html,
body,
#root {
  margin: 0;
  font-size: 13px;
  line-height: 1;
  color: var(--palette-foreground);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  height: 100%;
}
#root {
  min-height: 100%;
}
:focus {
  outline: none;
}

#root {
  display: flex;
  flex-direction: column;
}

:focus {
  outline: none;
}

.main {
  position: relative;
  flex: 1 0 auto;
  height: 100%;
  background: var(--palette-main-bg);
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: calc(1280px + (var(--sp-4) * 2));
  padding: 0 var(--sp-4);
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-height: 0;
  margin: 0 auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a,
.link {
  display: inline-flex;
  align-items: center;
  color: var(--palette-foreground);
  fill: var(--palette-foreground);
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    fill: var(--palette-primary);
    color: var(--palette-primary);
  }
}

.blue-link {
  color: var(--palette-primary);
  &:hover {
    color: var(--palette-primary-darken-2);
  }
}

.caption {
  color: var(--palette-neutral);
  &__mark {
    color: var(--palette-foreground);
  }
}

.d-flex {
  display: flex;
  &.__column {
    flex-direction: column;
  }
  &.__row {
    flex-direction: row;
  }
  &.__align-center {
    align-items: center;
  }
  &.__justify-center {
    justify-content: center;
  }
  &.__wrap {
    flex-wrap: wrap;
  }
  &.__space-between {
    justify-content: space-between;
  }
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.truncate-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hidden {
  display: none;
}

.divider-bottom {
  &:not(:last-child) {
    @include divider;
  }

  &__single {
    @include divider;
  }

  &__solid {
    border-bottom: 1px solid rgba(var(--palette-neutral-lighten-2-rgb), 0.45);
  }
}
