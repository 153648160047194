.error-page {
  box-sizing: border-box;
  justify-content: center;
  max-width: 698px;
  margin: 100px auto auto auto;
  padding: 22px 0;

  @media screen and (min-width: 768px) {
    margin: 180px auto auto auto;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Inter", "Arial", "Helvetica CY", "Nimbus Sans L", sans-serif;
  }
  min-height: 100vh;

  .link-like {
    display: inline-flex;
    background: transparent;
    min-width: auto;
    width: auto;
    box-shadow: none;
    outline-style: none;
    border-radius: 0;
    height: auto;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    color: #d6008f;
  }
  .caption {
    font-size: 16px;
  }

  &__content-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding: 16px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 64px;
      padding: 0;
    }
  }

  &__image {
    width: 311px;
    height: 297px;
    flex-shrink: 0;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__title,
  &__admin-notice {
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 16px 0;
    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      display: block;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #dddddf;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);

    &.__expanded {
      transform: rotate(0);
    }
  }

  &__button.link-like {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    padding: 0 16px;
    border: none;
    background-color: #ffffff;
  }

  &__icon {
    margin-left: 4px;
  }

  &__error-wrapper {
    width: 100%;
    padding: 0 16px;
    max-height: 56px;
    margin-top: 16px;
    overflow: hidden;
    transition: all 0.3s ease-in;
    @media screen and (min-width: 768px) {
      padding: 0;
    }

    &.__expanded {
      max-height: 500px;
    }
  }

  &__error-stack {
    width: 100%;
    height: 350px;
    padding: 16px;
    word-break: break-word;
    white-space: pre-wrap;
    border-radius: 14px;
    border: 1px solid #dddddf;
    overflow-y: auto;
  }
}
