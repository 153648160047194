@import "../../styles/mixins/media";

.add-to-favorite {
  &.base-button {
    width: 45px;
    height: 45px;
    padding: var(--sp-3);
    @include portrait-tablet {
      width: auto;
    }
  }

  &__icon .icon {
    fill: var(--palette-warning);
  }

  &__text {
    display: block;
    margin-left: var(--sp-2);
  }
}
