@import "../../styles/mixins/media";

.base-form {
  &__field:not(:last-child) {
    margin-bottom: var(--sp-2);
  }
  &__error {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 var(--sp-2);
    max-width: 320px;
    margin: var(--sp-3) auto;
    text-align: center;
    color: var(--palette-negative);
    background: var(--palette-negative-bg);
    height: 50px;
    border-radius: 10px;
    &.__warning {
      color: var(--palette-warning-darken-1);
      background: rgba(var(--palette-warning-lighten-2-rgb), 0.5);
    }

    &-icon {
      margin-right: var(--sp-2);
      font-size: 16px;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    & > * {
      width: 100%;
    }
  }

  @include portrait-tablet {
    &__field:not(:last-child) {
      margin-bottom: var(--sp-4);
    }
    &__error {
      margin-top: 0;
      margin-bottom: var(--sp-6);
    }
  }
}
