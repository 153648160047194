@import "../../styles/mixins/media";

.base-card {
  background: var(--palette-white);
  border-radius: 10px;
  padding: 30px;
  position: relative;

  @include desktop-up {
    padding: 40px;
  }
}
