@import "../../styles/mixins/commonJoinForm";

.share {
  &__heading {
    margin-bottom: var(--sp-4);
    padding: 0 var(--sp-4);
  }

  &__text {
    margin-bottom: var(--sp-6);
    padding: 0 var(--sp-4);
    letter-spacing: -0.01em;
  }

  &__input {
    input {
      transition: background-color 0.15s linear, border 0.25s linear;
    }
    fill: var(--palette-foreground);
  }

  @include desktop-up {
    &__heading {
      margin-bottom: var(--sp-4);
      padding: 0;
    }
    &__text {
      padding: 0;
    }
  }
}
