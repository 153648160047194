.status-filter {
  &__header {
    font-size: 15px;
    line-height: 15px;
    margin-bottom: var(--sp-6);
    text-align: left;
  }

  &__checkbox {
    width: auto;
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: var(--sp-6);
    }
  }
}
