.base-rating {
  color: var(--palette-neutral);

  &.__error {
    .base-rating__star {
      fill: var(--palette-negative);
    }
  }

  &__star-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &__star {
    fill: var(--palette-neutral);
    &.__active {
      fill: var(--palette-warning);
    }
    &:not(:first-child) {
      margin-right: var(--sp-1);
    }
  }

  &.__enabled {
    cursor: pointer;
    .base-rating__star:hover {
      & ~ .base-rating__star,
      &.base-rating__star {
        fill: var(--palette-warning);
      }
    }
  }

  &__error-message {
    color: var(--palette-negative);
  }
}
