@import "../../../../../../styles/mixins/media";

.appointments-pricing {
  margin: 0 auto;

  &__text {
    color: var(--palette-neutral);
  }

  &__prices {
    &-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: var(--sp-6) 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    &-label {
      width: 100%;
      margin-bottom: var(--sp-3);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-input {
      width: 130px;
      input::placeholder {
        color: var(--palette-foreground);
      }
    }
    &-duration {
      margin-left: var(--sp-3);
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: var(--sp-8);
  }

  @include desktop-up {
    &__prices {
      &-item {
        padding: var(--sp-4) 0;
      }
      &-label {
        width: 0;
        flex-grow: 1;
        margin-bottom: 0;
      }
      &-input {
        margin-left: var(--sp-6);
      }
      &-duration {
        min-width: 65px;
        text-align: right;
      }
    }
    &__save-btn {
      width: auto;
    }
  }
}
