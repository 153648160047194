@import "../../styles/mixins/media";
@import "../../styles/mixins/typography";

.content-header {
  @include headline-2;
  margin-bottom: var(--sp-5);
  text-align: center;

  @include portrait-tablet {
    @include headline-1;
    margin-bottom: var(--sp-6);
  }
}
