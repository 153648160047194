@import "../../styles/mixins/typography";

.base-select {
  &.__disabled {
    .base-select {
      &__label {
        color: var(--palette-neutral);
      }
      &__text {
        color: var(--palette-neutral);
      }
      &__select {
        pointer-events: none;
      }
      &__wrapper {
        cursor: default;
        &,
        &:hover,
        &:focus-within {
          box-shadow: inset 0 0 0 1px rgba(var(--palette-neutral-rgb), 0.3);
        }
      }
      &__icon {
        fill: var(--palette-neutral);
        color: var(--palette-neutral);
      }
    }
  }

  &__label {
    display: block;
    margin-bottom: var(--sp-2);
    @include label;
    color: var(--palette-neutral);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 50px;
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px rgba(var(--palette-neutral-rgb), 0.5);
    position: relative;

    overflow: hidden;
    transition: border-color 0.25s ease-in-out;
    &:hover {
      box-shadow: inset 0 0 0 1px rgba(var(--palette-foreground-rgb), 0.5);
    }
    &:focus-within {
      box-shadow: inset 0 0 0 1px var(--palette-primary);
    }
  }

  &__text {
    color: var(--palette-foreground);
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.__disabled {
      color: var(--palette-neutral);
    }
  }

  &__select {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none;
  }

  &__wrapper-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.__error {
    .base-select__wrapper {
      box-shadow: inset 0 0 0 1px var(--palette-negative);
    }
  }
}
