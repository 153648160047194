@import "../../styles/mixins/typography";

.base-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--sp-n1);

  &__item {
    display: flex;
    align-items: center;
    border-radius: 5px;
    white-space: nowrap;
    @include small;
    letter-spacing: 0;
    padding: 0 var(--sp-2);
    height: 26px;
    margin-right: var(--sp-1);
    margin-bottom: var(--sp-1);
    color: var(--palette-foreground);
    background: var(--palette-positive-bg);
  }
}
